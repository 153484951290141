/**
 * @typedef {ReturnType<typeof import('widgets/product/ColorSwatch').default>} BaseColorSwatch
 */

/**
 * @description Base ColorSwatch implementation
 * @param {BaseColorSwatch} BaseColorSwatch Base widget for extending
 * @returns {typeof ColorSwatch} Color Swatch widget
 */
export default function (BaseColorSwatch) {
    /**
     * @class ColorSwatch
     * @augments BaseColorSwatch
     */
    class ColorSwatch extends BaseColorSwatch {
        prefs() {
            return {
                classesShowAll: 'm-show_all',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            if (this.isSelectedOptionHidden()) {
                this.toggleView();
            }
        }

        isSelectedOptionHidden() {
            return Object.values(this.refs).some(ref => {
                return ref.data('variationSwatch')
                    ? ref.data('attrIsSelected') && ref.data('attrIsHidden')
                    : false;
            });
        }

        toggleView() {
            if (this.showAll) {
                this.ref('self').removeClass(this.prefs().classesShowAll);
                this.showAll = false;
            } else {
                this.ref('self').addClass(this.prefs().classesShowAll);
                this.showAll = true;
            }

            this.eventBus().emit('highlighter.update');
        }
    }

    return ColorSwatch;
}
