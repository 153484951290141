import { TWidget } from 'widgets/Widget';
// TODO: JSDoc for all methods
// TODO: freeze loaded sections during update with aria-busy
// TODO: investigate proper implementation of aria-live region for updated sections
// TODO: keep track that focus stays on the same place during PLP update

import { submitFormJson, submitFormJsonWithAbort } from 'widgets/toolbox/ajax';
import { showErrorLayout } from 'widgets/toolbox/util';
import { debounce } from 'widgets/toolbox/debounce';

/**
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputStepper').default>>} InputStepper
 */

/**
 * @description Base ProductDetail implementation
 * @param Widget Base widget for extending
 * @returns Product Detail widget
 */
export default function (Widget: TWidget) {
    /**
     * @class ProductDetail
     * @augments Widget
     * @classdesc Represents ProductDetail component with next features:
     * 1. Support keyboard navigation for accessibility
     * 2. Rerender PDP and Quick View
     * 3. Handle product attributes change
     * 4. Handle add to cart event
     * @property {boolean} [data-ready-to-order=false] - ready to order flag
     * @property {string} data-text-select-options - select option text
     * @property {string} data-text-stock-limit - stock limit text
     * @property {string} data-out-of-stock-label - out of stock label
     * @property {string} data-bundled-products - bundled products
     * @property {number} data-selected-quantity - selected quantity
     * @category widgets
     * @subcategory product
     * @example
     * cartridges/app_storefront_widgets/cartridge/templates/default/product/productDetails.isml
     *
     * <main
     *     role="main"
     *     class="l-pdp-main"
     *     data-widget="productDetail"
     *     data-ready-to-order="${product.readyToOrder}"
     *     data-text-network-error="${Resource.msg('error.alert.network', 'product', null)}"
     *     data-text-select-options="${Resource.msg('error.alert.select.options', 'product', null)}"
     *     data-text-stock-limit="${Resource.msg('error.alert.stock.limit', 'product', null)}"
     *     data-out-of-stock-label="${Resource.msg('label.outofstock', 'common', null)}"
     *     data-add-to-cart-label="${Resource.msg('button.addtocart', 'common', null)}"
     *     data-selected-quantity="${product.selectedQuantity}"
     *     data-analytics="${JSON.stringify(product.gtmInfo)}"
     *     data-pid="${product.id}"
     *     data-tau-product-id="${product.id}"
     *     data-add-to-wishlist-hide-texts="false"
     *     data-show-minicart-on-product-add.md.lg.xl="true"
     *     data-show-message-on-product-add="true"
     *     data-bundled-products="${JSON.stringify(product.bundledProducts)}"
     *     data-text-added-to-wishlist="${Resource.msg('button.added.to.wishlist', 'wishlist', null)}"
     *     data-accessibility-alerts='{
     *         "quantitychanged": "${Resource.msg('alert.quantitychanged', 'product', null)}",
     *         "variationselected": "${Resource.msg('alert.variationselected', 'product', null)}",
     *         "optionselected": "${Resource.msg('alert.optionselected', 'product', null)}",
     *         "addedtocart": "${Resource.msg('alert.addedtocart', 'product', null)}",
     *         "addedtowishlist": "${Resource.msg('alert.addedtowishlist', 'product', null)}"
     *     }'
     * >
     *     PDP content
     * </main>
     */
    class ProductDetail extends Widget {
        prefs() {
            return {
                addToCartMsg: 'addToCartMsg',
                backInStock: 'backInStock',
                disableHistory: false,
                descriptions: 'descriptions',
                currentProductId: '',
                readyToOrder: false,
                selectedQuantity: 0,
                textSelectOptions: '',
                textStockLimit: '',
                outOfStockLabel: '',
                updateLabel: '',
                update: false,
                accessibilityAlerts: {
                    quantitychanged: '',
                    // eslint-disable-next-line spellcheck/spell-checker
                    optionselected: '',
                    // eslint-disable-next-line spellcheck/spell-checker
                    variationselected: ''
                },
                productOptions: [
                    {
                        id: '',
                        selectedValueId: '',
                        customOptionValue: null,
                        values: [
                            {
                                isCustomOption: false,
                                isCustomOptionSelected: false
                            }
                        ]
                    }
                ],
                bundledProducts: [],
                ...super.prefs()
            };
        }

        /**
         * @description Widget initialization
         * @returns {void}
         */
        init() {
            super.init();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
            this.changeAttributeRequests = [];
        }

        /**
         * @description updateProduct handler
         * @param {object} updateBtn Response object
         * @returns {Promise<object>} Promise object represents server response for product updating
         */
        updateProduct(updateBtn) {
            const productOption = this.prefs().productOptions[0];

            const selectedOptions = this.getSelectedProductOptions();
            const isSelectedOptionsValid = this.validateProductOptions(selectedOptions);

            if (!isSelectedOptionsValid) {
                return Promise.resolve(null);
            }

            return submitFormJson(
                updateBtn.ref('self').attr('data-update-url'),
                {
                    pid: this.prefs().currentProductId || updateBtn.prefs().pid,
                    quantity: this.prefs().selectedQuantity || updateBtn.ref('self').attr('data-selected-quantity') || 1,
                    uuid: updateBtn.ref('self').attr('data-uuid'),
                    ...this.getAdditionalProductData(productOption)
                }
            )
                .then((response) => {
                    this.afterUpdateProduct(response);

                    return response;
                })
                .finally(() => {
                    this.emit('updated');
                });
        }

        /**
         * @description afterUpdateProduct handler
         * @param {object} response Response object
         * @emits "product.updated"
         * @returns {void}
         */
        afterUpdateProduct(response) {
            /**
             * @description Event to notify concerned widgets, that product was updated
             * @event "product.updated"
             */
            this.eventBus().emit('product.updated', response);
        }

        /**
         * @description Update Product View
         * @param {any} product productModel
         * @returns {void}
         */
        updateProductView(product) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentGtmInfo' does not exist on type '... Remove this comment to see the full error message
            this.currentGtmInfo = product.gtmInfo;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'uuid' does not exist on type 'ProductDet... Remove this comment to see the full error message
            this.uuid = product.uuid;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'stock' does not exist on type 'ProductDe... Remove this comment to see the full error message
            this.stock = product.availability && product.availability.stock ? product.availability.stock : null;

            this.setPref('selectedQuantity', product.selectedQuantity);
            this.setPref('currentProductId', product.id);
            this.setPref('readyToOrder', product.readyToOrder);

            this.setGtmInfo(product);

            this.getById(this.prefs().addToCartMsg, (addToCartMsg) => {
                addToCartMsg.hide();
            });

            this.getById(this.prefs().backInStock, (backInStock) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'updateProductId' does not exist on type ... Remove this comment to see the full error message
                backInStock.updateProductId(product.id);
            });

            this.renderVariationAttributes(product);
            this.renderProductOptions(product);
            this.renderQuantities(product);
            this.renderPrice(product);
            this.renderAvailability(product);
            this.renderImages(product);
            this.renderName(product);
            this.renderPromotions(product);
            this.renderProductDescriptions(product);
            this.updateSocialLinks(product);
            this.updateViewFullProductURL(product);
            this.updateRatings(product);
        }

        /**
         * @description Update product reviews block
         * @param {object} product Product object
         * @returns {Promise<void|null>} Promise object represents ratings rendering result
         */
        updateRatings(product) {
            const ratings = [1, 2, 3, 4, 5].map((currentValue, index) => {
                const starObj = {
                    position: index > 0 ? index * 20 : 0
                };

                if (product.rating >= currentValue) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFull' does not exist on type '{ positi... Remove this comment to see the full error message
                    starObj.isFull = true;
                } else if ((product.rating % 1 > 0) && (Math.ceil(product.rating) >= currentValue)) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHalf' does not exist on type '{ positi... Remove this comment to see the full error message
                    starObj.isHalf = true;
                } else {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isEmpty' does not exist on type '{ posit... Remove this comment to see the full error message
                    starObj.isEmpty = true;
                }

                return starObj;
            });
            let renderingResult;

            this.has('productRatings', (productRatings) => {
                const link = productRatings.data('addProductLinkToRatingsLink')
                    ? product.selectedProductUrl
                    : '';

                renderingResult = this.render('productRatingsPDPTemplate', { product, ratings, link }, productRatings);
            });

            return renderingResult || Promise.resolve(null);
        }

        /**
         * @description Render product view full product url
         * @param {object} product Product object
         * @returns {void}
         */
        updateViewFullProductURL(product) {
            if (product.selectedProductUrl) {
                this.has('viewFullProductURL', (el) => {
                    el.attr('href', product.selectedProductUrl);
                });
            }
        }

        /**
         * TODO: Move from widget properties to preferences
         *
         * @description Set GTM info
         * @param {object} product Product object
         * @returns {void}
         */
        setGtmInfo(product) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentGtmInfo' does not exist on type '... Remove this comment to see the full error message
            this.currentGtmInfo = product.gtmInfo;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentGtmInfo' does not exist on type '... Remove this comment to see the full error message
            if (this.currentGtmInfo && product.selectedQuantity) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentGtmInfo' does not exist on type '... Remove this comment to see the full error message
                this.currentGtmInfo.quantity = product.selectedQuantity;
            }
        }

        /**
         * @description Render product options
         * @param {object} product Product object
         * @returns {void}
         */
        renderProductOptions(product) {
            const CUSTOM_OPTION_VALUE_ID = 'CUSTOM_TEXT';
            const options = product.options;

            if (!options && !options.length) { return; }

            options.forEach(productOption => {
                const productOptionId = productOption.id;

                this.getById('option-' + productOptionId, (option) => {
                    let previousCustomOptionValue = '';

                    if (productOption.selectedValueId === CUSTOM_OPTION_VALUE_ID) {
                        option.getById('customOptionText', (customOptionText) => {
                            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | RefElement' is not assignable to ty... Remove this comment to see the full error message
                            previousCustomOptionValue = customOptionText.ref('field').val();
                        });
                    }

                    option.render('template', { option: productOption }, option.ref('container'))
                        .then(() => {
                            this.setPref('productOptions', options);

                            if (productOption.selectedValueId === CUSTOM_OPTION_VALUE_ID) {
                                option.getById('customOptionText', (customOptionText) => {
                                    customOptionText.ref('field').val(previousCustomOptionValue);
                                });
                            }
                        });
                });
            });
        }

        /**
         * @description Render product attributes
         * @param {object} product Product object
         * @returns {void}
         */
        renderVariationAttributes(product) {
            if (product.variationAttributes && product.variationAttributes.length) {
                product.variationAttributes.forEach(variationAttribute => {
                    variationAttribute.values = variationAttribute.values.map(value => {
                        return {
                            ...value,
                            selected: value.selected,
                            disabled: !value.selectable,
                            selectable: value.selectable ? 'selectable' : ''
                        };
                    });
                    this.getById('attr-' + variationAttribute.attributeId, (attribute) => {
                        attribute.render('template', {
                            attr: variationAttribute
                        }, attribute.ref('container'));
                    });
                });
            }
        }

        /**
         * @description Executes when user clicks on product details link.
         * Usually used by analytics etc.
         * @emits "detail.product.link.click"
         * @param {refElement} link - clicked product tile link
         * @returns {void}
         */
        onProductLinkClick(link) {
            this.eventBus().emit('detail.product.link.click', link);
        }

        /**
         * @description Render product quantity
         * @param {object} product Product object
         * @returns {void}
         */
        renderQuantities(product) {
            const availability = product.availability;
            const response = product.quantities;

            if (!response || !availability) { return; }

            this.getById('quantity', (quantity) => {
                quantity.render(
                    'template',
                    {
                        attr: {
                            currentQty: response.currentValue,
                            max: response.max,
                            min: response.min,
                            step: response.step
                        },
                        isOutOfStock: (availability && availability.isOutOfStock) || false
                    },
                    quantity.ref('container')
                ).then(function () {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'update' does not exist on type 'Widget'.
                    quantity.update();
                    quantity.setPref('attrUrl', response.actionUrl);
                });
            });
        }

        /**
         * @description Render product name
         * @param {object} product Product object
         * @returns {void}
         */
        renderName(product) {
            this.getById('productName', (productName) => {
                productName.render('template', { product }, productName.ref('container'));
            });
        }

        /**
         * @description Render product promotion
         * @param {object} product Product object
         * @returns {void}
         */
        renderPromotions(product) {
            this.getById('promotions', (promotions) => {
                promotions.render('template', {
                    promotions: product.promotions || []
                }, promotions.ref('container'));
            });
        }

        /**
         * @description Render product price
         * @param {object} product Product object
         * @returns {void}
         */
        renderPrice(product) {
            if (product.price && product.price.html) {
                this.getById('priceBlock', (priceBlock) => {
                    priceBlock.render('template', {}, undefined, product.price.html);
                });
            }
        }

        /**
         * @description Render product availability
         * @param {object} product Product object
         * @returns {void}
         */
        renderAvailability(product) {
            let message = '';
            let availabilityClass = '';

            if (product.availability
                && product.availability.messages
                && (product.readyToOrder || product.isInaccessibleVariant)
            ) {
                message = product.availability.messages.join('');
                availabilityClass = product.availability.class;
            }

            if (product.availability.isReachedLimit) {
                message = product.availability.inStockMsg.join('');
            }

            this.has('productAvailabilityMsg', productAvailabilityMsg => {
                productAvailabilityMsg.hide();
            });

            this.getById('availability', (availabilityLabel) => {
                availabilityLabel.render('template', {
                    message: message,
                    class: availabilityClass
                }, availabilityLabel.ref('container'));
            });
        }

        /**
         * @description Render product images
         * @param {object} product Product object
         * @returns {void}
         */
        renderImages(product) {
            this.getById('productImages', (productImages) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'renderImages' does not exist on type 'Wi... Remove this comment to see the full error message
                productImages.renderImages(product);
            });
        }

        /**
         * @description Render product description
         * @param {object} product Product object
         * @returns {void}
         */
        renderProductDescriptions(product) {
            this.getById(this.prefs().descriptions, descriptionsAccordion => {
                descriptionsAccordion.render(
                    'template',
                    product,
                    descriptionsAccordion.ref('container')
                // @ts-expect-error ts-migrate(2551) FIXME: Property 'reinit' does not exist on type 'Widget'.... Remove this comment to see the full error message
                ).then(() => descriptionsAccordion.reinit());
            });
        }

        /**
         * @description Update social links
         * @param {object} product Product object
         * @returns {void}
         */
        updateSocialLinks(product) {
            Object.keys(product.socialLinks).forEach((socialKey) => {
                this.has('social-link-' + socialKey, link => {
                    link.attr('href', product.socialLinks[socialKey]);
                });
            });
        }

        /**
         * @description trigger change from different widgets (swatch, select)
         * Saves `selectWidget.id` into a property for further global notifications etc.
         * Later, when triggering event, `selectWidget.id` will be analyzed in order to send with event correct data.
         * @param {InputStepper} selectWidget widget
         * @returns {Promise<object|null>} Promise object represents server response with attribute changing result
         */
        changeAttribute(selectWidget) {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'changeAttributeID' does not exist on typ... Remove this comment to see the full error message
            this.changeAttributeID = selectWidget.id;
            const selected = selectWidget.getSelectedOptions();

            if (!selected || selected.data('attrIsSelected')) {
                return Promise.resolve(null);
            }

            const requestObject = submitFormJsonWithAbort(selected.data('attrUrl'), undefined, 'GET', true);

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
            this.changeAttributeRequests.push(requestObject);

            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            return requestObject.promise
                .then((response) => {
                    this.afterChangeAttribute(response);

                    return response;
                })
                .catch(e => {
                    if (e.name === 'AbortError') {
                        return;
                    }

                    showErrorLayout(e);
                })
                .finally(() => {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
                    const index = this.changeAttributeRequests.indexOf(requestObject);

                    if (index > -1) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
                        this.changeAttributeRequests.splice(index, 1);
                    }
                });
        }

        /**
         * @description After change attribute handler
         * @param {object} response response object
         * @returns {void}
         */
        afterChangeAttribute(response) {
            if (response && response.product) {
                if (!this.prefs().disableHistory) {
                    this.updateHistoryState(response.product);
                }

                this.updateProductView(response.product);
                this.triggerChangeAttributeEvent(response);
            }
        }

        /**
         * @description Aborts redundant update product quantity requests
         * @returns {void}
         */
        dismissQty() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
            this.changeAttributeRequests.forEach((request) => {
                request.abortController.abort();
            });

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
            this.changeAttributeRequests = [];
        }

        /**
         * @description Triggers global event after `afterChangeAttribute` method executed.
         * Puts localized global alert event description
         * @param {object} response response object
         * @returns {void}
         */
        triggerChangeAttributeEvent(response) {
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'changeAttributeID' does not exist on typ... Remove this comment to see the full error message
            if (!this.changeAttributeID) {
                return;
            }

            let accessibilityAlert = '';

            // @ts-expect-error ts-migrate(2551) FIXME: Property 'changeAttributeID' does not exist on typ... Remove this comment to see the full error message
            if (this.changeAttributeID.indexOf('quantity') === 0) {
                accessibilityAlert = this.prefs().accessibilityAlerts.quantitychanged;
                this.showAlert(accessibilityAlert, response);
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'changeAttributeID' does not exist on typ... Remove this comment to see the full error message
            } else if (this.changeAttributeID.indexOf('attr-') === 0) {
                // eslint-disable-next-line spellcheck/spell-checker
                accessibilityAlert = this.prefs().accessibilityAlerts.variationselected;
                this.showAlert(accessibilityAlert, response);
            // @ts-expect-error ts-migrate(2551) FIXME: Property 'changeAttributeID' does not exist on typ... Remove this comment to see the full error message
            } else if (this.changeAttributeID.indexOf('option-') === 0) {
                // eslint-disable-next-line spellcheck/spell-checker
                accessibilityAlert = this.prefs().accessibilityAlerts.optionselected;

                // since Options changes applied immediately we need to delay success
                // message to not bother keyboard users

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'productOptionsSelect' does not exist on ... Remove this comment to see the full error message
                if (!this.productOptionsSelect) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'productOptionsSelect' does not exist on ... Remove this comment to see the full error message
                    this.productOptionsSelect = debounce(() => {
                        this.showAlert(accessibilityAlert, response);
                    }, 2000, false, true);
                }

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'productOptionsSelect' does not exist on ... Remove this comment to see the full error message
                this.productOptionsSelect({});
            }
        }

        /**
         * @description Show global alert after `triggerChangeAttributeEvent` method executed.
         * @param {string} accessibilityAlert Alert message text
         * @param {object} response response object
         * @returns {void}
         */
        showAlert(accessibilityAlert, response) {
            this.eventBus().emit('alert.show', {
                accessibilityAlert
            });

            // @ts-expect-error ts-migrate(2551) FIXME: Property 'changeAttributeID' does not exist on typ... Remove this comment to see the full error message
            this.changeAttributeID = undefined;

            this.afterUpdateProduct(response);
        }

        /**
         * @description Update History State
         * @param {object} product product object
         */
        updateHistoryState(product) {
            window.history.replaceState(undefined, '', product.selectedProductUrl);
        }

        /**
         * @description Validate product options
         * @param selectedOptions Selected product options
         * @returns Result of the validation product options
         */
        validateProductOptions(selectedOptions: Array<Record<string, unknown>|null>): boolean {
            return false;
        }

        /**
         * @description Get selected product options
         * @returns Result
         */
        getSelectedProductOptions(): Array<Record<string, unknown>|null> {
            return [];
        }

        /**
         * @description Returns additional product data object
         * @param productOption - product option
         * @returns result
         */
        getAdditionalProductData(productOption?: Record<string, unknown>): Record<string, unknown> {
            return {};
        }
    }

    return ProductDetail;
}
