import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/product/AddToCartMixin').default>} BaseAddToCartMixin
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Button').default>>} button
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputTextTypeNumber').default>>} inputTextTypeNumber
 * @typedef {InstanceType<ReturnType<typeof import('core/forms/ajax/EVoucherDataForm').default>>} eVoucherDataForm
 */

/**
 * @param {BaseAddToCartMixin} BaseAddToCartMixin Base widget for extending
 * @returns {typeof EProductAddToCartMixin} EProductAddToCartMixin class
 */

export default function (BaseAddToCartMixin) {
    /**
     * @category widgets
     * @subcategory product
     * @class EProductAddToCartMixin
     * @augments BaseAddToCartMixin
     * @classdesc Represents EProductAddToCartMixin component
    */

    class EProductAddToCartMixin extends BaseAddToCartMixin {
        prefs() {
            return {
                removeProductUrl: '',
                eproductType: 'gift-certificate',
                ...super.prefs()
            };
        }

        /**
         * @description Add product to cart depending on e-product type
         * If e-product type is `e-voucher`, validates e-voucher data form and in case if form is not valid - not performing add to cart action
         * @param {button} button add to cart button
         * @returns {void}
         */
        addToCart(button) {
            this.validateAndSubmit(button, this.doAddToCart);
        }

        /**
         * @description Method, which sends a real request for add e-product to cart
         * Includes amount of e-product, chosen by customer
         * @param {button} button add to cart button
         * @param {object} eVoucherData e-voucher form data if exists
         * @returns {void}
         */
        doAddToCart(button, eVoucherData = {}) {
            if (this.prefs().processingRequest) {
                return;
            }

            if (!this.prefs().readyToOrder) {
                this.showCartMessage(this.prefs().textSelectOptions, true);

                return;
            }

            if (this.stock && this.stock < this.prefs().selectedQuantity) {
                this.showCartMessage(this.prefs().textStockLimit, true);

                return;
            }

            this.setPref('processingRequest', true);
            button.startProcess();

            this.showProgressBar();

            const addToCartBtnPrefs = button.prefs();
            const amount = this.getEProductAmount();

            submitFormJson(addToCartBtnPrefs.addToCartUrl, {
                pid: this.prefs().currentProductId || addToCartBtnPrefs.pid,
                quantity: this.prefs().selectedQuantity || addToCartBtnPrefs.selectedQuantity,
                // @ts-ignore
                options: this.getSelectedProductOptions(),
                childProducts: this.prepareChildProducts(this.prefs().bundledProducts),
                amount: amount > 0 ? amount.toString() : '',
                ...eVoucherData
            }).then((response) => {
                this.setPref('processingRequest', false);
                this.postAddProduct(response);

                button.stopProcess();
                this.hideProgressBar();
            })
                .catch(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();

                    this.showCartMessage(this.prefs().textNetworkError, true);
                });
        }

        /**
         * @description Action after success add to cart.
         * If product type is `gift-certificate` - no changes to base flow
         * If product type is `e-voucher`:
         * In case if no error - trying to save e-voucher data to PLI on server
         * In case of saving e-voucher data error - removes PLI from cart
         * @param {object} response server response
         * @returns {void}
         */
        postAddProduct(response) {
            if (this.prefs().eproductType !== 'e-voucher') {
                super.postAddProduct(response);

                return;
            }

            if (response.eVoucherFormErrors) {
                this.getById('eVoucherDataForm', eVoucherDataForm => {
                    eVoucherDataForm.onSubmitted(response);
                });

                return;
            }

            super.postAddProduct(response);
        }
    }

    return EProductAddToCartMixin;
}
