import { scrollWindowTo } from 'widgets/toolbox/scroll';
import { getContentByUrl, getJSONByUrl } from 'widgets/toolbox/ajax';
import { timeout } from 'widgets/toolbox/util';
import { showErrorLayout } from 'widgets/toolbox/util';
import { submitFormJsonWithAbort } from 'widgets/toolbox/ajax';

const CLICK_EVENT_ID = 'navigation.click.event';
const GTM_EVENT_NAME = 'data-layer-event';
const GTM_LABEL_SELECTOR = 'gtmEventLabel';

const REVIEWS_REF_ID = 'reviews';
/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductDetail').default>} ProductDetailBase
 * @typedef {InstanceType<ReturnType<typeof import('core/product/Availability').default>>} availability
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputStepper').default>>} InputStepper
 */

/**
 * @description ProductDetail widget
 * @param {ProductDetailBase} ProductDetailBase Base widget for extending
 * @returns {typeof ProductDetail} ProductDetail class
 */

export default function (ProductDetailBase) {
    /**
     * @class ProductDetail
     * @augments ProductDetailBase
     */
    class ProductDetail extends ProductDetailBase {
        prefs() {
            return {
                addToCartMsgSticky: 'addToCartMsgSticky',
                addToCartStickyHeader: 'add-to-cart-sticky-header',
                ingredientsSection: 'ingredients-section',
                descriptions: 'descriptions',
                pdpSlots: ['viewDetail', 'pdpBottom1', 'pdpBottom2', 'pdpBottom3', 'pdpBottom4'],
                storeChangedUrl: '',
                slotsUrl: '',
                ...super.prefs(),
                productOptions: []
            };
        }

        init() {
            super.init();

            this.handleProductAvailability();
            this.updateQuantityWithLimit();
            this.loadAnalytics();

            this.eventBus().on('preferredstore.selected', 'onPreferredStoreSelected');
        }

        /**
         * @description loads gtm analytics on page load
         */
        loadAnalytics() {
            this.eventBus().emit('product.details.open', [this.ref('self')]);
        }

        /**
         * @description Handles OOS product logic
         * @returns {void}
         */
        handleProductAvailability() {
            this.has('productRecommendations', productRecommendations => {
                productRecommendations[
                    this.isProductAvailableForHomeDelivery() ? 'show' : 'hide'
                ]();
            });
        }

        /**
         * @description Update quantity on inputStepper with limits
         */
        updateQuantityWithLimit() {
            this.getById('quantity', (quantity) => {
                quantity.setInputValue(quantity.filterInput(quantity.currentValue), false);
            });
        }

        /**
         * @description Update Product View
         * @param {any} product productModel
         * @returns {void}
         */
        updateProductView(product) {
            super.updateProductView(product);

            this.updateRecommendations(product);

            this.getById(this.prefs().addToCartMsgSticky, (addToCartMsgSticky) => {
                addToCartMsgSticky.hide();
            });

            this.getById(this.prefs().addToCartStickyHeader, (addToCartStickyHeader) => {
                addToCartStickyHeader.setText(product.productName);
            });

            this.handleProductAvailability();

            // Due to multiplee sub-element async rendering need to fire an event in a next timer tick
            timeout(() => this.emit('productviewupdated'));
        }

        /**
         * @description Update PDP recommendation slots
         * @param {object} product product model from response
         * @returns {void}
         */
        updateRecommendations(product) {
            getContentByUrl(this.prefs().slotsUrl, {
                pid: product.id
            }).then(response => {
                if (!response) {
                    return;
                }

                const wrapper = document.createElement('div');

                wrapper.innerHTML = response;

                this.prefs().pdpSlots.forEach(slot => {
                    this.has(slot, slotRef => {
                        const slotContentFromResponse = wrapper.querySelector('#' + slot);

                        if (slotContentFromResponse) {
                            slotRef.empty().prepend(slotContentFromResponse.innerHTML);
                        }
                    });
                });

                wrapper.remove();
            });
        }

        /**
         * @description Update product BazaarVoice reviews block
         * @param {object} product Product object
         * @returns {Promise<void|null>} Promise object represents ratings rendering result
         */
        updateRatings(product) {
            // update ratings summary
            this.has('ratingSummary', ratingSummary => {
                ratingSummary.data('bvProductid', product.id);
                const productRedirectUrl = ratingSummary.data('bvRedirectUrl');

                if (productRedirectUrl) {
                    ratingSummary.data('bvRedirectUrl', product.link);
                }
            });

            // update product reviews
            this.has('reviews', reviews => {
                reviews.data('bvProductid', product.id);
            });

            const ratings = [1, 2, 3, 4, 5].map((currentValue, index) => {
                const starObj = {
                    position: index > 0 ? index * 18 : 0
                };

                if (product.rating >= currentValue) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isFull' does not exist on type '{ positi... Remove this comment to see the full error message
                    starObj.isFull = true;
                } else if ((product.rating % 1 > 0) && (Math.ceil(product.rating) >= currentValue)) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isHalf' does not exist on type '{ positi... Remove this comment to see the full error message
                    starObj.isHalf = true;
                } else {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'isEmpty' does not exist on type '{ posit... Remove this comment to see the full error message
                    starObj.isEmpty = true;
                }

                return starObj;
            });

            this.has('productRatings', (productRatings) => {
                const link = productRatings.data('addProductLinkToRatingsLink')
                    ? product.selectedProductUrl
                    : '';

                this.render('productRatingsPDPTemplate', { product, ratings, link }, productRatings);
            });

            return Promise.resolve(null);
        }

        /**
         * @description Render product availability
         * @param {object} product Product object
         * @returns {void}
         */
        renderAvailability(product) {
            let message = '';
            let availabilityClass = '';

            if (product.availability
                && product.availability.messages
                && (product.readyToOrder || product.isInaccessibleVariant)
            ) {
                message = product.availability.messages.join('');
                availabilityClass = product.availability.class;
            }

            if (product.availability.isReachedLimit) {
                message = product.availability.inStockMsg.join('');
            }

            this.has('productAvailabilityMsg', productAvailabilityMsg => {
                productAvailabilityMsg.hide();
            });

            this.getById('availability', (/** @type {availability} */availabilityLabel) => {
                availabilityLabel.setAvailability(product.pdpAvailability || {});
                this.eventBus().emit('update.availability');
                availabilityLabel.render('template', {
                    message: message,
                    class: availabilityClass,
                    backInStockSubscriptionEnabled: product.backInStockSubscription?.isEnabled,
                    pdpAvailability: product.pdpAvailability
                }, availabilityLabel.ref('container'));
            });
        }

        /**
         * @description Scroll to Reviews section
         * @returns {void}
         */
        scrollToReviews() {
            this.has(REVIEWS_REF_ID, reviews => scrollWindowTo(reviews.get(), true));
        }

        /**
         * @description Update availability for Click & Collect and Store on store changed
         * @returns {void}
         */
        onPreferredStoreSelected() {
            const storeChangedUrl = this.prefs().storeChangedUrl;

            if (storeChangedUrl) {
                getJSONByUrl(storeChangedUrl, {
                    pid: this.prefs().currentProductId || this.prefs().pid,
                    quantity: this.prefs().selectedQuantity
                }, false).then(response => {
                    const product = response.product;

                    this.renderAvailability(product);
                    this.renderQuantities(product);
                    this.callIfExists('renderAddToCart', response.product);
                });
            }
        }

        /**
         * @description Indicates if product is available
         * @returns {boolean} result
         */
        isProductAvailableForHomeDelivery() {
            let productAvailable = true;

            const availabilityObject = this.getProductAvailabilityObject();

            if (availabilityObject) {
                productAvailable = !!availabilityObject.available;
            }

            return productAvailable;
        }

        /**
         * @description Render product quantity
         * @param {object} product Product object
         * @returns {void}
         */
        updateIngredients(product) {
            this.getById(this.prefs().descriptions, (descriptions) => {
                descriptions.getById(this.prefs().ingredientsSection, (ingredientsSection) => {
                    ingredientsSection.ref('ingredientsText').empty();

                    if (product.ingredients) {
                        ingredientsSection.ref('ingredientsText').append(product.ingredients);
                    }
                });
            });
        }

        /**
         * @description trigger change from different widgets (swatch, select)
         * Saves `selectWidget.id` into a property for further global notifications etc.
         * Later, when triggering event, `selectWidget.id` will be analyzed in order to send with event correct data.
         * @param {InputStepper} selectWidget widget
         * @returns {Promise<object|null>} Promise object represents server response with attribute changing result
         */
        changeAttribute(selectWidget) {
            this.changeAttributeID = selectWidget.id;
            const selected = selectWidget.getSelectedOptions();

            if (!selected || selected.data('attrIsSelected')) {
                return Promise.resolve(null);
            }

            const requestObject = submitFormJsonWithAbort(selected.data('attrUrl'), undefined, 'GET', true);
            const isQuantityUpdate = selectWidget.data('quantityUpdate');

            this.changeAttributeRequests.push(requestObject);

            this.eventBus().emit('init.attribute.change');

            Promise.all(this.changeAttributeRequests.map(req => req.promise)).then(() => {
                this.eventBus().emit('finalize.attribute.change');
            });

            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            return requestObject.promise
                .then((response) => {
                    if (isQuantityUpdate) {
                        this.afterChangeQuantity(response);
                    } else {
                        this.afterChangeAttribute(response);
                    }

                    return response;
                })
                .catch(e => {
                    if (e.name === 'AbortError') {
                        return;
                    }

                    showErrorLayout(e);
                })
                .finally(() => {
                    const index = this.changeAttributeRequests.indexOf(requestObject);

                    if (index > -1) {
                        this.changeAttributeRequests.splice(index, 1);
                    }
                });
        }

        /**
         * @description Render product quantity
         * @param {object} product Product object
         * @returns {void}
         */
        renderQuantities(product) {
            const availability = product.availability;
            const response = product.quantities;

            if (!response || !availability) { return; }

            this.getById('quantity', (quantity) => {
                quantity.render(
                    'template',
                    {
                        attr: {
                            currentQty: response.currentValue,
                            max: response.max,
                            min: response.min,
                            step: response.step
                        },
                        isOutOfStock: (availability && availability.isOutOfStock) || false
                    },
                    quantity.ref('container')
                ).then(function () {
                    quantity.update();
                    quantity.setPref('attrUrl', response.actionUrl);
                });
            });
        }

        /**
         * @description Prepares product specification to rendering
         * @param {object} product - Product object
         * @returns {void}
         */
        prepareProductSpecification(product) {
            const { specifications } = product;

            (specifications || []).forEach((specification) => {
                if ((specification.id === 'sustVegan' || specification.id === 'sustVeget')) {
                    specification.sustVegan = true && specification.value;
                } else if (specification.id === 'FSCIcon') {
                    specification.FSCIcon = true && specification.value;
                } else if (specification.id === 'hazardousIcons') {
                    specification.hazardousIcons = true;
                } else if (specification.id === 'washcare') {
                    specification.washcare = true;
                } else {
                    specification.default = true;
                }
            });
        }

        /**
         * @description Prepares product to description rendering
         * @param {object} product - Product object
         * @returns {void}
         */
        prepareProductDetailAttributes(product) {
            this.prepareProductSpecification(product);
        }

        /**
         * @description Render product description
         * @param {object} product - Product object
         * @returns {void}
         */
        renderProductDescriptions(product) {
            this.prepareProductDetailAttributes(product);

            this.getById(this.prefs().descriptions, descriptionsAccordion => {
                descriptionsAccordion.render(
                    'template',
                    product,
                    descriptionsAccordion.ref('container')
                // @ts-expect-error ts-migrate(2551) FIXME: Property 'reinit' does not exist on type 'Widget'.... Remove this comment to see the full error message
                ).then(() => {
                    if (product && product.ingredients) {
                        this.updateIngredients(product);
                    }

                    descriptionsAccordion.reinit();
                });
            });
        }

        /**
         * @description After change quantity handler
         * @param {object} response response object
         * @returns {void}
         */
        afterChangeQuantity(response) {
            if (response && response.product) {
                if (!this.prefs().disableHistory) {
                    this.updateHistoryState(response.product);
                }

                this.updateProductQuantityView(response.product);

                this.triggerChangeAttributeEvent(response);
            }
        }

        /**
         * @description Update Product Quantity View
         * @param {any} product productModel
         * @returns {void}
         */
        updateProductQuantityView(product) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'currentGtmInfo' does not exist on type '... Remove this comment to see the full error message
            this.currentGtmInfo = product.gtmInfo;

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'stock' does not exist on type 'ProductDe... Remove this comment to see the full error message
            this.stock = product.availability && product.availability.stock ? product.availability.stock : null;

            this.setPref('selectedQuantity', product.selectedQuantity);
            this.setPref('readyToOrder', product.readyToOrder);

            this.setGtmInfo(product);

            this.renderQuantities(product);
            this.renderPrice(product);
            this.renderAvailability(product);
            this.renderPromotions(product);
        }

        /**
         * @description Click Event handler
         * @listens dom#click
         * @param {HTMLElement} el Source of event
         * @param {Event} event Event object
         * @returns {void}
         */
        showRelatedIdeasClick(el) {
            this.eventBus().emit(CLICK_EVENT_ID, {
                event: GTM_EVENT_NAME,
                eventCategory: 'Key interactions',
                eventAction: 'Show ideas',
                eventLabel: el.data(GTM_LABEL_SELECTOR)
            });
        }

        /**
         * @description Get product availability data
         * @returns {object|undefined} product availability object
         */
        getProductAvailabilityObject() {
            let result;

            this.getById('availability', (/** @type {availability} */availability) => {
                const availabilityObject = availability.getAvailability();

                if (typeof availabilityObject === 'string') {
                    result = JSON.parse(availabilityObject);
                } else {
                    result = availabilityObject;
                }
            });

            return result;
        }

        /**
         * @description Get similar products carousel header
         * @returns {string} similar products carousel header
         */
        getSimilarProductsCarouselHeader() {
            const availabilityObject = this.getProductAvailabilityObject();
            let topSlotText;

            if (availabilityObject && availabilityObject.topSlotRef) {
                switch (availabilityObject.topSlotRef) {
                    case 'ccStoreAvailability':
                        topSlotText = this.prefs().ccStoreTopSlotAvailabilityText;
                        break;
                    case 'ccAvailability':
                        topSlotText = this.prefs().ccTopSlotAvailabilityText;
                        break;
                    case 'oosAvailability':
                        topSlotText = this.prefs().oosTopSlotAvailabilityText;
                        break;
                    default:
                        break;
                }
            }

            return topSlotText;
        }

        /**
         * @description Triggers global event after `afterChangeAttribute` method executed.
         * @param {object} response response object
         * @returns {void}
         */
        triggerChangeAttributeEvent(response) {
            if (this.changeAttributeID && this.changeAttributeID.indexOf('attr-') === 0) {
                this.eventBus().emit('product.attribute.select', response.product);

                if (response.virtualPageLoad && response.analyticsPageData) {
                    this.eventBus().emit('virtual.page.load', response.analyticsPageData);
                }
            }

            super.triggerChangeAttributeEvent(response);
        }
    }

    return ProductDetail;
}
