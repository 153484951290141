import localStorageWrapper from 'widgets/toolbox/localStorageWrapper';

/**
 * @typedef {ReturnType<typeof import('core/product/ProductDetail').default>} ProductDetail
 */

/**
 * @param {ProductDetail} ProductDetail Base widget for extending
 * @returns {typeof PlusProductDetail} plusProductDetail class
 */

export default function (ProductDetail) {
    /**
     * @category widgets
     * @subcategory product
     * @class PlusProductDetail
     * @augments ProductDetail
     * @classdesc Represents PlusProductDetail component
    */

    class PlusProductDetail extends ProductDetail {
        init() {
            super.init();
            this.eventBus().on('remove.product.line.item', 'updateAddToCartBtn');
        }

        updateAddToCartBtn() {
            const itemIds = localStorageWrapper.getItem('cartItemIds');

            if (!this.prefs().isCustomerSubscribed && itemIds && !itemIds.includes(this.prefs().pid)) {
                this.getById('addToCart', addToCartBtn => {
                    addToCartBtn.enable();
                    addToCartBtn.setText(this.prefs().addToCartLabel);
                });

                this.getById('addToCartSticky', addToCartBtn => {
                    addToCartBtn.enable();
                    addToCartBtn.setText(this.prefs().addToCartLabel);
                });
            }
        }
    }

    return PlusProductDetail;
}
