import { scrollWindowTo } from 'widgets/toolbox/scroll';
import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @description Base AddAllToCartMixin implementation
 *
 * This class is not intended to have a separate DOM representation.
 *
 * @param ProductSet Base widget for extending
 * @returns Add to Cart mixin widget
 */
export default function (ProductSet: ReturnType<typeof import('widgets/product/ProductSet').default>) {
    /**
     * @class AddAllToCartMixin
     * @augments ProductSet
     * @subcategory product
     * @classdesc Represents product set component with the next features:
     * 1. Adds all product set products to the basket
     * 2. Shows alert message after adding product set products to the basket
     *
     * @property {string} data-text-network-error - network error text
     */
    class AddAllToCartMixin extends ProductSet {
        prefs() {
            return {
                pid: '',
                processingRequest: false,
                selectedQuantity: '',
                showMinicartOnProductAdd: '',
                showAlertOnProductAdd: '',
                addToCartGlobalMsg: '',
                textNetworkError: '',
                accessibilityAlerts: {
                    addedsettocart: ''
                },
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.eventBus().on('product.updated', 'onProductItemUpdated');
        }

        /**
         * @description If product set has unselected product variation,
         * show error message for all such products, scroll to the first one error and set focus first product with error
         * @returns {void}
         */
        handleUnselectedProduct() {
            let unselectedProduct: HTMLElement|undefined;

            this.eachChild((child) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'productSetItem' does not exist on type '... Remove this comment to see the full error message
                if (child.prefs().productSetItem && (!child.prefs().readyToOrder)) {
                    child.getById('addToCartMsg', (addToCartMsg) => {
                        const error = true;

                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'textSelectOptions' does not exist on typ... Remove this comment to see the full error message
                        const msg = child.prefs().textSelectOptions;

                        addToCartMsg.render(
                            'template',
                            { msg, error },
                            addToCartMsg.ref('container')
                        ).then(() => addToCartMsg.show());
                    });

                    if (!unselectedProduct) {
                        unselectedProduct = <HTMLElement> child.getById(
                            'productName',
                            (productName) => productName.ref('titleLink').get()
                        );
                    }
                }
            });

            if (unselectedProduct) {
                unselectedProduct.focus();
                scrollWindowTo(unselectedProduct, true);
            }
        }

        /**
         * @description Returns product set data for the set items
         * @returns {Array} result
         */
        getProductSetItems() {
            const productSetItems = [];

            this.eachChild((child) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'productSetItem' does not exist on type '... Remove this comment to see the full error message
                if (child.prefs().productSetItem) {
                    let isOutOfStock = false;

                    child.getById('addToCart', (addToCart) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'isOutOfStock' does not exist on type '{ ... Remove this comment to see the full error message
                        isOutOfStock = !!addToCart.prefs().isOutOfStock;
                    });

                    productSetItems.push({

                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                        pid: child.prefs().currentProductId || child.prefs().pid,

                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                        quantity: child.prefs().selectedQuantity,

                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type 'never'.
                        available: !isOutOfStock,

                        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
                        readyToOrder: child.prefs().readyToOrder
                    });
                }
            });

            return productSetItems;
        }

        /**
         * @description Returns an array with products available for ordering
         * @param {Array} productSetItems - product set data for the set items
         * @returns {Array} result
         */
        getPidsObj(productSetItems) {
            return productSetItems.filter(setItem => setItem.available).map((childProduct) => {
                return {
                    pid: childProduct.pid,
                    qty: childProduct.quantity
                };
            });
        }

        /**
         * @description On add all to cart button click handler
         * @param {any} button - button
         * @returns {Promise<object|null>} Promise object represents server response for session continuation
         */
        onAddAllToCart(button) {
            if (this.prefs().processingRequest) {
                return Promise.resolve(null);
            }

            const productSetItems = this.getProductSetItems();

            // @ts-expect-error ts-migrate(2339) FIXME: Property 'readyToOrder' does not exist on type 'ne... Remove this comment to see the full error message
            const unselectedProduct = productSetItems.find((setItem) => !setItem.readyToOrder && setItem.available);

            if (unselectedProduct) {
                this.handleUnselectedProduct();

                return Promise.resolve(null);
            }

            const pidsObj = this.getPidsObj(productSetItems);
            const isFullProductSet = pidsObj.length === productSetItems.length;

            this.setPref('processingRequest', true);
            button.startProcess();

            this.getById(this.prefs().addToCartGlobalMsg, (addToCartGlobalMsg) => addToCartGlobalMsg.hide());

            return submitFormJson(button.prefs().addToCartUrl, {
                pid: this.prefs().pid || button.prefs().pid,
                quantity: this.prefs().selectedQuantity || button.prefs().selectedQuantity,
                pidsObj: JSON.stringify(pidsObj)
            })
                .then(response => {
                    this.postAddAllToCart(response, isFullProductSet, pidsObj.length);

                    return response;
                })
                .finally(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                }).catch(() => {
                    this.showAddAllToCartAlert(true, this.prefs().textNetworkError);
                });
        }

        /**
         * @description Handles a result of product set adding to cart
         * @param {object} response - response
         * @param {boolean} isFullProductSet - Identifies if all product set items are added
         * @param {number} productsCount - products count to be added to the cart
         * @returns {void}
         */
        postAddAllToCart(response, isFullProductSet, productsCount) {
            if (!response.error && response.cart && response.messages) {
                const cartModel = response.cart;

                cartModel.showMinicart = this.prefs().showMinicartOnProductAdd;

                const isAllProductsAdded = response.messages && (response.messages.length === productsCount);
                const message = isFullProductSet && isAllProductsAdded
                    ? this.prefs().accessibilityAlerts.addedsettocart
                    : response.messages.join('<br>');

                this.showAddAllToCartAlert(false, message);

                this.eventBus().emit('product.added.to.cart', cartModel, this);
            } else {
                this.showAddAllToCartAlert(true, response.message);
            }
        }

        /**
         * @description Shows alert message after adding a product set to the cart
         * @param {boolean} error - identifies if message should be shown as an error
         * @param {*} message - message
         * @returns {void}
         */
        showAddAllToCartAlert(error, message) {
            if (!this.prefs().showAlertOnProductAdd) {
                return;
            }

            this.getById(this.prefs().addToCartGlobalMsg, (addToCartGlobalMsg) => {
                addToCartGlobalMsg.render(
                    'template',
                    {
                        msg: message,
                        error
                    },
                    addToCartGlobalMsg.ref('container')
                ).then(() => addToCartGlobalMsg.show());
            });
        }

        onProductItemUpdated() {
            if (!this.prefs().showAlertOnProductAdd) {
                return;
            }

            this.getById(this.prefs().addToCartGlobalMsg, (addToCartGlobalMsg) => {
                addToCartGlobalMsg.hide();
            });
        }
    }

    return AddAllToCartMixin;
}
