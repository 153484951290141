/**
 * @typedef {ReturnType<typeof import('core/product/SetProductDetail').default>} SetProductDetail
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputStepper').default>>} InputStepper
 */

/**
 * @param {SetProductDetail} SetProductDetail Base SetProductDetail for extending
 * @returns {typeof BuyTogetherProduct} Availability class
 */

export default function (SetProductDetail) {
    /**
     * @class ProductDetail
     * @augments SetProductDetail
     */
    class BuyTogetherProduct extends SetProductDetail {
        prefs() {
            return {
                productAvailable: false,
                recommenderProduct: false,
                recommendationProductId: '',
                lowStockMessage: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.recommendationAvailable = this.prefs().productAvailable;
        }

        /**
         * @description Handles product pre selection
         */
        preselectProduct() {
            if (!this.prefs().productAvailable) {
                return;
            }

            this.ref('self').addClass(this.prefs().selectedClass);

            this.getById('selectProduct', (selectProduct) => {
                selectProduct.preselectCheckbox();
            });

            if (this.prefs().recommenderProduct) {
                this.getById('selectProduct', (selectProduct) => {
                    selectProduct.disableCheckbox();
                });
            }
        }

        /**
         * @description After change attribute handler
         * @param {object} response response object
         * @returns {void}
         */
        afterChangeAttribute(response) {
            super.afterChangeAttribute(response);

            this.eventBus().emit('update.buy.together.recommendation', response?.product?.id, this.getRecommendationProductId());
        }

        /**
         * @description Updates selection flag
         * @param {boolean} selectionFlag - selection flag
         * @param {string} selectionValue - selection value
         */
        updateProductSelection(selectionFlag) {
            if (!selectionFlag) {
                this.getById('selectProduct', (selectProduct) => {
                    selectProduct.unselectCheckbox();
                });
            } else if (selectionFlag) {
                this.getById('selectProduct', (selectProduct) => {
                    selectProduct.preselectCheckbox();
                });
            }
        }

        /**
         * @description updates availability section
         * @param {object} product - product object
         * @returns {Promise} rendering promise
         */
        updateAvailabilitySection(product) {
            if (product) {
                const isProductSelected = product.buyTogetherRecommender || this.productSelected() === 'on';

                product.selected = isProductSelected;
                product.disabled = product.buyTogetherRecommender;

                this.updateProductSelection(isProductSelected);
            }

            this.recommendationAvailable = product.available;

            return this.render('availabilityTogetherTemplate', product, this.ref('availabilityTogetherContainer'));
        }

        /**
         * @description updates availability badge
         * @param {object} product - product object
         * @returns {void}
         */
        updateAvailabilityBadge(product) {
            if (!product) {
                return;
            }

            const isReachedLimit = (product.readyToOrder || product.isInaccessibleVariant) && product.availability.isReachedLimit;
            let lowStockMessage = '';

            if (product.availability.isLowStock) {
                lowStockMessage = product.availability.messages[0];
            } else if (product.plpAvailability.available && product.plpAvailability.stock) {
                lowStockMessage = this.prefs().lowStockMessage.replace('{0}', product.plpAvailability.stock);
            }

            const renderingData = {
                reachedLimitMessage: isReachedLimit ? product.availability.isReachedLimitMessage : '',
                hasPromotions: !!product.promotions,
                promotions: product.promotions,
                lowStockMessage
            };

            this.render('additionalInfoTemplate', renderingData, this.ref('additionalInfoContainer'));
        }

        /**
         * @description Updates recommendation item quantity
         * @param {number} selectedQuantity - selected quantity
         */
        updateQuantity(selectedQuantity) {
            this.config.selectedQuantity = selectedQuantity;

            this.getById('quantityBlock', (quantityBlock) => {
                quantityBlock.render(
                    'template',
                    { selectedQuantity },
                    quantityBlock.ref('container')
                );
            });
        }

        /**
         * @description Updates recommendation item total
         * @param {object} product - recommendation product
         * @param {object} product.subtotal - recommendation product subtotal
         */
        updateTotal(product) {
            if (!product.subtotal) {
                return;
            }

            this.ref('totalPrice').hide();

            const productSetPriceBlock = this.getById('totalPriceBlock', (priceBlock) => {
                return priceBlock.ref('self');
            });

            this.render('setPriceTpl', { price: product.subtotal }, productSetPriceBlock);
        }

        /**
         * @description gets recommendation product id
         * @returns {string} recommendation product id
         */
        getRecommendationProductId() {
            return this.prefs().recommendationProductId;
        }
    }

    return BuyTogetherProduct;
}
