import { getJSONByUrl } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('core/product/ProductSet').default>} ProductSet
 * @typedef {ReturnType<typeof import('core/product/BuyTogetherProduct').default>} BuyTogetherProduct
 */

/**
 * @param {ProductSet} ProductSet Base ProductSet for extending
 * @returns {typeof BuyTogetherProduct} Availability class
 */

export default function (ProductSet) {
    /**
     * @class ProductDetail
     * @augments ProductSet
     */
    class BuyTogetherProduct extends ProductSet {
        prefs() {
            return {
                preselectFirstCollection: false,
                preselectRecommendations: false,
                selectRecommendationItemUrl: '',
                updateBuyTogetherItemsUrl: '',
                priceState: null,
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.initEvents();

            this.preselectRecommendations();
            this.initRecommendationTotal();
        }

        /**
         * @description inits widget events
         */
        initEvents() {
            this.eventBus().on('update.buy.together.recommendation', 'updateRecommendation');
        }

        /**
         * @description inits buy together recommendation total price
         */
        initRecommendationTotal() {
            if (this.prefs().priceState) {
                this.updatePriceTotal(this.prefs().priceState);
                this.togglePriceTotals(true, false);
                this.manageAddSetToCart(true);
            }
        }

        /**
         * Getters
         */

        /**
         * @description gets all buy together items
         * @returns {Array.<BuyTogetherProduct>} buy together products
         */
        getBuyTogetherItems() {
            const buyTogetherProduct = this.getConstructor('buyTogetherProduct');
            const recommendations = [];

            this.eachChild((child) => {
                if (child instanceof buyTogetherProduct) {
                    recommendations.push(child);
                }
            });

            return recommendations;
        }

        /**
         * @description gets all selected buy together items
         * @returns {Array.<BuyTogetherProduct>} selected buy together products
         */
        getSelectedRecommendations() {
            const recommendationItems = this.getBuyTogetherItems();
            const selectedRecommendations = recommendationItems.filter((recommendationItem) => {
                return recommendationItem.productSelected() && !recommendationItem.prefs().recommenderProduct;
            });

            return selectedRecommendations;
        }

        /**
         * @description gets all pattern products
         * @returns {Array.<BuyTogetherProduct>} pattern products
         */
        getRecommender() {
            const recommendationItems = this.getBuyTogetherItems();
            const selectedRecommendations = recommendationItems.filter((recommendationItem) => {
                return recommendationItem.prefs().recommenderProduct;
            });

            return selectedRecommendations;
        }

        /**
         * @description gets selected buy together products
         * @returns {Array.<BuyTogetherProduct>} selected buy together products
         */
        getSelectedBuyTogetherItems() {
            const recommendationItems = this.getBuyTogetherItems();
            const selectedBuyTogetherItems = recommendationItems.filter((recommendationItem) => {
                return recommendationItem.productSelected();
            });

            return selectedBuyTogetherItems;
        }

        /**
         * @description gets selected buy together items IDs
         * @returns {Array.<string>} array with items IDs
         */
        getSelectedRecommendationIDs() {
            const selectedRecommendations = this.getSelectedBuyTogetherItems();
            const recommendationIDs = (selectedRecommendations || []).map((selectedRecommendation) => {
                return selectedRecommendation.getProductId().toString();
            });

            return recommendationIDs;
        }

        /**
         * @typedef {object} ProductSetItem
         * @property {boolean} available - availability flag
         * @property {number} pid - product id
         * @property {string} quantity - product quantity
         */

        /**
         * @description returns an array with products available for ordering
         * @param {Array.<ProductSetItem>} productSetItems - product set data for the set items
         * @returns {Array.<object>} result
         */
        getPidsObj(productSetItems) {
            return productSetItems.filter(setItem => setItem.available).map((childProduct) => {
                const pid = typeof childProduct.pid === 'number'
                    ? childProduct.pid.toString()
                    : childProduct.pid;

                return {
                    pid,
                    qty: childProduct.quantity
                };
            });
        }

        /**
         * @description gets recommendation variants
         * @returns {object} result
         */
        getRecommendationsVariants() {
            const recommendationItems = this.getBuyTogetherItems();

            const selectedRecommendationVariants = recommendationItems.reduce((recommendationVariants, recommendationItem) => {
                const recommendationItemID = recommendationItem.getRecommendationProductId().toString();
                const recommendationVariantID = recommendationItem.getProductId().toString();

                if (recommendationItemID !== recommendationVariantID) {
                    recommendationVariants[recommendationItemID] = recommendationVariantID;
                }

                return recommendationVariants;
            }, {});

            return selectedRecommendationVariants;
        }

        /**
         * Methods
         */

        /**
         * @description updates buy together recommendation total
         * @param {object} priceTotal - buy together recommendation total price
         */
        updatePriceTotal(priceTotal) {
            const productSetPriceBlock = this.getById('priceBlock', (priceBlock) => priceBlock.ref('self'));

            this.render('setPriceTpl', { price: priceTotal }, productSetPriceBlock);
        }

        /**
         * @description preselects recommendation items
         */
        preselectRecommendations() {
            if (!this.prefs().preselectRecommendations) {
                return;
            }

            const recommendations = this.getBuyTogetherItems();

            recommendations.forEach((recommendation) => recommendation.preselectProduct());
        }

        /**
         * @description updates recommendation items
         * @param {Array.<object>} recommendationItems - updated recommendation items
         */
        updateRecommendationItems(recommendationItems) {
            recommendationItems.forEach((recommendationItem) => {
                this.getById(`buyTogetherProduct-${recommendationItem.buyTogetherRecommendationID}`, (buyTogetherProduct) => {
                    buyTogetherProduct.updateQuantity(recommendationItem.recommendationQuantity);
                    buyTogetherProduct.updateTotal(recommendationItem);
                    buyTogetherProduct.updateAvailabilitySection(recommendationItem);
                    buyTogetherProduct.updateAvailabilityBadge(recommendationItem);
                });
            });
        }

        /**
         * @description checks that buy together recommendation set is unavailable
         * @returns {boolean} result
         */
        isBuyTogetherRecommendationsUnavailable() {
            const allRecommendations = this.getBuyTogetherItems();

            return allRecommendations.every((recommendation) => {
                return !recommendation.recommendationAvailable;
            });
        }

        /**
         * @description checks that product recommendation set has any selected recommendations
         * @returns {boolean} result
         */
        hasSelectedProducts() {
            const selectedProducts = this.getSelectedBuyTogetherItems();

            return !!selectedProducts.length;
        }

        /**
         * @description handles updates for unavailable buy together recommendation set
         * @param {object} total - buy together recommendation total price
         */
        handleAvailabilityRecommendationSet(total) {
            if (this.isBuyTogetherRecommendationsUnavailable() || total.zeroPrice) {
                this.togglePriceTotals(false, true);
                this.manageAddSetToCart(false);
            } else {
                const hasAnySelectedProducts = this.hasSelectedProducts();

                if (hasAnySelectedProducts) {
                    this.togglePriceTotals(true, false);
                    this.updatePriceTotal(total);
                } else {
                    this.togglePriceTotals(false, true);
                }

                this.manageAddSetToCart(hasAnySelectedProducts);
            }
        }

        /**
         * @description handles product selection result
         * @param {object} response - result
         * @param {object} response.total - updated total price
         */
        handleSelectedProductResult(response) {
            if (response && response.total) {
                const { total } = response;
                const hasAnySelectedProducts = this.hasSelectedProducts();

                if (hasAnySelectedProducts) {
                    const productSetPriceBlock = this.getById('priceBlock', (priceBlock) => priceBlock.ref('self'));

                    this.render('setPriceTpl', { price: total }, productSetPriceBlock)
                        .then(this.togglePriceTotals.bind(this, true, false));
                } else {
                    this.togglePriceTotals(false, true);
                }

                this.manageAddSetToCart(hasAnySelectedProducts);
            } else {
                this.togglePriceTotals(false, true);
                this.manageAddSetToCart(false);
            }
        }

        /**
         * @description handles recommendation update
         * @param {object} response - updating result
         * @param {string} updatedProductID - updated product id
         * @param {string} recommendationID - recommendation product id
         * @returns {void}
         */
        handleUpdateRecommendation(response, updatedProductID, recommendationID) {
            if (!updatedProductID || !recommendationID) {
                return;
            }

            const recommendationVariant = (response.recommendationItems || []).find((recommendationItem) => {
                return recommendationItem.id === updatedProductID;
            });

            if (!recommendationVariant) {
                return;
            }

            this.getById(`buyTogetherProduct-${recommendationID}`, (buyTogetherProduct) => {
                buyTogetherProduct.updateQuantity(recommendationVariant.recommendationQuantity);
                buyTogetherProduct.updateTotal(recommendationVariant);
                buyTogetherProduct.updateAvailabilitySection(recommendationVariant);
                buyTogetherProduct.updateAvailabilityBadge(recommendationVariant);
            });

            if (recommendationVariant.buyTogetherRecommender) {
                this.getById('buyTogetherPattern', (buyTogetherProduct) => {
                    buyTogetherProduct.updateQuantity(recommendationVariant.recommendationQuantity);
                    buyTogetherProduct.updateTotal(recommendationVariant);
                    buyTogetherProduct.updateAvailabilityBadge(recommendationVariant);
                    buyTogetherProduct.updateAvailabilitySection(recommendationVariant)
                        .then(() => { this.handleAvailabilityRecommendationSet(response.total); });
                });
            } else {
                this.handleAvailabilityRecommendationSet(response.total);
            }
        }

        /**
         * @description handles collection update result
         * @param {object} response - result
         * @param {Array.<object>} response.recommendationItems - array with updated recommendations
         * @param {object} response.total - updated buy together total
         */
        handleUpdateCollectionResult(response) {
            if (!response || !response.recommendationItems || !response.total) {
                return;
            }

            this.handleAvailabilityRecommendationSet(response.total);
            this.updateRecommendationItems(response.recommendationItems);
        }

        /**
         * Callbacks
         */

        /**
         * @description Server request to get product set info/prices etc depending on pre-selected products
         * @returns {void}
         */
        onProductSelected() {
            const selectedRecommendations = this.getSelectedRecommendationIDs();
            const recommendationsVariants = this.getRecommendationsVariants();
            let currentCollection = '';

            this.getById('collectionSelector', (collectionSelector) => {
                currentCollection = collectionSelector.getValue();
            });

            getJSONByUrl(this.prefs().selectRecommendationItemUrl, {
                recommenderProductID: this.prefs().pid,
                selectedBuyTogetherItems: selectedRecommendations.join(','),
                selectedCollection: currentCollection,
                recommendationsVariants: JSON.stringify(recommendationsVariants)
            }).then(this.handleSelectedProductResult.bind(this));
        }

        /**
         * @description handles collection update event
         * @param {import("widgets/toolbox/RefElement").RefElement} collectionSelector - collection selector
         */
        onUpdateCollection(collectionSelector) {
            const selectedRecommendations = this.getSelectedRecommendationIDs();
            const recommendationsVariants = this.getRecommendationsVariants();

            getJSONByUrl(this.prefs().updateBuyTogetherItemsUrl, {
                recommenderProductID: this.prefs().pid,
                selectedBuyTogetherItems: selectedRecommendations.join(','),
                selectedCollection: collectionSelector.getValue(),
                recommendationsVariants: JSON.stringify(recommendationsVariants)
            }).then(this.handleUpdateCollectionResult.bind(this));
        }

        /**
         * @description updates recommendation
         * @param {object} updatedProduct - updated product
         * @param {string} recommendationID - recommendation id
         */
        updateRecommendation(updatedProduct, recommendationID) {
            const selectedRecommendations = this.getSelectedRecommendationIDs();
            const recommendationsVariants = this.getRecommendationsVariants();
            let currentCollection = '';

            this.getById('collectionSelector', (collectionSelector) => {
                currentCollection = collectionSelector.getValue();
            });

            getJSONByUrl(this.prefs().updateBuyTogetherItemsUrl, {
                recommenderProductID: this.prefs().pid,
                selectedBuyTogetherItems: selectedRecommendations.join(','),
                selectedCollection: currentCollection,
                recommendationsVariants: JSON.stringify(recommendationsVariants)
            }).then((response) => this.handleUpdateRecommendation(response, updatedProduct, recommendationID));
        }
    }

    return BuyTogetherProduct;
}
