// @ts-nocheck
import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/product/AddToCartMixin').default>} AddToCartMixin
 * @typedef {ReturnType<typeof import('widgets/global/ProcessButton').default>} ProcessButton
 */

/**
 * @description just added product to cart mixin widget
 * @param {AddToCartMixin} AddToCartMixin Base widget for extending
 * @returns {typeof EProductJustAddedProductToCartMixin} ProductDetail class
 */
export default function (AddToCartMixin) {
    class EProductJustAddedProductToCartMixin extends AddToCartMixin {
        prefs() {
            return {
                removeProductUrl: '',
                eproductType: 'gift-certificate',
                ...super.prefs()
            };
        }

        /**
         * @description Add product to cart depending on e-product type
         * If e-product type is `e-voucher`, validates e-voucher data form and in case if form is not valid - not performing add to cart action
         * @param {button} button add to cart button
         * @returns {void}
         */
        addToCart(button) {
            const addToCartBtnPrefs = button.prefs();

            if (!addToCartBtnPrefs.justAddedRecommendationAddToCart) {
                this.validateAndSubmit(button, this.doAddToCart);

                return;
            }

            this.validateAndSubmit(button, this.addJustAddedProductRecommendation);
        }

        /**
         * @description Action after success add to cart.
         * If product type is `gift-certificate` - no changes to base flow
         * If product type is `e-voucher`:
         * In case if no error - trying to save e-voucher data to PLI on server
         * In case of saving e-voucher data error - removes PLI from cart
         * @param {object} response server response
         * @returns {void}
         */
        postAddProduct(response) {
            if (this.prefs().eproductType === 'e-voucher' && response.eVoucherFormErrors) {
                this.getById('eVoucherDataForm', eVoucherDataForm => {
                    eVoucherDataForm.onSubmitted(response);
                });

                return;
            }

            if (response.justAddedProductCart && !this.isEProductAddedFromWishlist(response.cart, response.pid)) {
                this.showJustAddedProductCart(response);

                if (!response.error && response.subscriptionUUID) {
                    this.eventBus().emit('added.subscription.product.line.item');
                }

                if (response.availability) {
                    this.postAddProductAvailability(response);
                }
            } else {
                this.showMiniCart(response);
            }
        }

        /**
         * @description Add Linked Subscription data to request
         * @param {object} params request
         */
        addLinkedSubscriptionObj(params) {
            this.eachChild((child) => {
                if (child.prefs().isLinkedSubscription) {
                    child.getById('selectSubscriptionProduct', (subscriptionProduct) => {
                        if (subscriptionProduct.getValue() === 'on') {
                            params.subscriptionPidObj = JSON.stringify({
                                pid: child.prefs().linkedSubscriptionId,
                                qty: 1
                            });
                        }
                    });
                }
            });
        }

        /**
         * @description Method, which sends a real request for add e-product to cart
         * Includes amount of e-product, chosen by customer
         * @param {button} button add to cart button
         * @param {object} eVoucherData e-voucher form data if exists
         * @returns {void}
         */
        doAddToCart(button, eVoucherData = {}) {
            if (this.prefs().processingRequest) {
                return;
            }

            if (!this.prefs().readyToOrder) {
                this.showCartMessage(this.prefs().textSelectOptions, true);

                return;
            }

            if (this.stock && this.stock < this.prefs().selectedQuantity) {
                this.showCartMessage(this.prefs().textStockLimit, true);

                return;
            }

            this.setPref('processingRequest', true);
            button.startProcess();

            this.showProgressBar();

            const addToCartBtnPrefs = button.prefs();
            const amount = this.getEProductAmount();

            const params = {
                pid: this.prefs().currentProductId || addToCartBtnPrefs.pid,
                quantity: this.prefs().selectedQuantity || addToCartBtnPrefs.selectedQuantity,
                // @ts-ignore
                options: this.getSelectedProductOptions(),
                childProducts: this.prepareChildProducts(this.prefs().bundledProducts),
                amount: amount > 0 ? amount.toString() : '',
                ...eVoucherData
            };

            this.addLinkedSubscriptionObj(params);

            submitFormJson(addToCartBtnPrefs.addToCartUrl, params).then((response) => {
                this.setPref('processingRequest', false);
                this.postAddProduct(response);

                button.stopProcess();
                this.hideProgressBar();
            })
                .catch(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();

                    this.showCartMessage(this.prefs().textNetworkError, true);
                });
        }

        /**
         * @description checks that is idea product
         *
         * @param {object} response - result of the adding to cart call
         * @param {object} response.cart - cart model
         * @param {string} response.pid - added product identifier
         * @returns {boolean} result
         */
        isIdeaProduct(response) {
            const { pid, cart } = response;

            const productModel = cart.items.find(item => item.id === pid);

            return productModel && productModel.isIdea;
        }

        showMiniCart(response) {
            if (!response.error && response.cart) {
                this.emit('productadded');
                const cartModel = response.cart;

                cartModel.showMinicart = this.prefs().showMinicartOnProductAdd;
                this.eventBus().emit('product.added.to.cart', cartModel, this);

                if (this.prefs().showAlertOnProductAdd) {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'addedtocart' does not exist on type '{ q... Remove this comment to see the full error message
                    const accessibilityAlert = this.prefs().accessibilityAlerts.addedtocart;

                    this.eventBus().emit('alert.show', {
                        accessibilityAlert
                    });
                }
            }

            if (response.error || this.prefs().showMessageOnProductAdd) {
                this.eventBus().emit('product.addedTocart.with.error', response);
                this.showCartMessage(response.message, response.error);
            }

            if (response && response.availability) {
                this.postAddProductAvailability(response);
            }
        }

        /**
         * @description checks that is product added from wishlist
         * @param {object} cart - cart object
         * @param {string} pid - recently added product id
         * @returns {boolean} result
         */
        isEProductAddedFromWishlist(cart, pid) {
            let result = false;

            if (!cart && !cart.items) {
                return result;
            }

            const justAddedItems = cart.items.filter((item) => item.id === pid);
            const justAddedItem = justAddedItems.pop();

            if (!justAddedItem) {
                return result;
            }

            result = (justAddedItem.isEVoucher || justAddedItem.isGiftCard) && justAddedItem.isAddedFromWishList;

            return result;
        }
    }

    return EProductJustAddedProductToCartMixin;
}
