import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('core/product/ProductDetail').default>} BaseProductDetail
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputStepper').default>>} InputStepper
 * @typedef {InstanceType<ReturnType<typeof import('core/forms/InputTextTypeNumber').default>>} inputTextTypeNumber
 */

/**
 * @param {BaseProductDetail} BaseProductDetail Base widget for extending
 * @returns {typeof EProductProductDetail} EProductProductDetail class
 */

export default function (BaseProductDetail) {
    /**
     * @category widgets
     * @subcategory product
     * @class EProductProductDetail
     * @augments BaseProductDetail
     * @classdesc Represents EProductProductDetail component
    */

    class EProductProductDetail extends BaseProductDetail {
        init() {
            super.init();
            this.otherAmountSelected = false;
        }

        /**
         * @description Validate eProduct forms
         * @param {object} button Dom element
         * @param {object} cb Callback function
         * @returns {void}
         */
        validateAndSubmit(button, cb) {
            const customAmountInput = this.getById('otherAmount', (/** @type {inputTextTypeNumber} */ customAmount) => customAmount);
            const eVoucherDataForm = this.getById('eVoucherDataForm', (/** @type {eVoucherDataForm} */ eVoucherData) => eVoucherData);
            const pass1 = customAmountInput.shown ? customAmountInput.validate() : true;
            const pass2 = eVoucherDataForm ? eVoucherDataForm.validate() : true;

            if (pass1 && pass2) {
                if (eVoucherDataForm) {
                    cb.bind(this, button, eVoucherDataForm.getFormFields())();
                } else {
                    cb.bind(this, button)();
                }
            }
        }

        /**
         * @description Get eProduct amount
         * @param {object} button Dom element
         * @param {object} cb Callback function
         * @returns {void}
         */
        getEProductAmount() {
            let amount = 0;

            this.getById('otherAmount', (/** @type {inputTextTypeNumber} */otherAmount) => {
                const otherAmountValue = otherAmount.getValue();

                amount = otherAmountValue ? parseInt(otherAmountValue, 10) : 0;
            });

            if (!amount) {
                this.getById('eproductAmount', (/** @type {select} */eproductAmount) => {
                    amount = eproductAmount.getValue();
                });
            }

            return amount;
        }

        /**
         * @description initial updateProduct handler
         * @param {object} updateBtn Response object
         * @returns {void}
         */
        updateProduct(updateBtn) {
            this.validateAndSubmit(updateBtn, this.doUpdateProduct);
        }

        /**
         * @description Update line item
         * @param {object} updateBtn Response object
         * @param {object} eVoucherData eVoucher form
         * @returns {Promise<object>} Promise object represents server response for product updating
         */
        doUpdateProduct(updateBtn, eVoucherData = {}) {
            const productOption = this.prefs().productOptions[0];
            const selectedOptions = this.getSelectedProductOptions();
            const isSelectedOptionsValid = this.validateProductOptions(selectedOptions);

            if (!isSelectedOptionsValid) {
                return Promise.resolve(null);
            }

            const amount = this.getEProductAmount();

            return submitFormJson(
                updateBtn.ref('self').attr('data-update-url'),
                {
                    pid: this.prefs().currentProductId || updateBtn.prefs().pid,
                    quantity: this.prefs().selectedQuantity || updateBtn.ref('self').attr('data-selected-quantity') || 1,
                    uuid: updateBtn.ref('self').attr('data-uuid'),
                    amount: amount > 0 ? amount.toString() : '',
                    ...eVoucherData,
                    ...this.getAdditionalProductData(productOption)
                }
            )
                .then((response) => {
                    this.afterUpdateProduct(response);

                    return response;
                })
                .finally(() => {
                    this.emit('updated');
                });
        }

        changeAmount(selectWidget) {
            const selected = selectWidget.getSelectedOptions();
            const otherAmountSelected = selected && selected.data('eproductCustomOption');

            this.getById('otherAmount', (/** @type {inputTextTypeNumber} */otherAmount) => {
                this.otherAmountSelected = otherAmountSelected;
                otherAmount[otherAmountSelected ? 'show' : 'hide']();
                otherAmount.setValue('', true);
                otherAmount.setRequired(false);

                if (otherAmountSelected) {
                    otherAmount.setRequired(true);
                    otherAmount.focus();
                }
            });
        }
    }

    return EProductProductDetail;
}
