import { setCookie, getCookie } from '../toolbox/cookie';

const DO_NOT_TRACKED_COOKIE = 'dw_dnt';
const RECENTLY_VIEWED_ENTRY = 'recentlyViewed';
const RECENTLY_VIEWED_ITEMS_LIMIT = 12;

/**
 * @description Base RecentlyViewedMixin implementation
 * @param ProductDetail widget for extending
 * @returns Recently Viewed Mixin
 */
export default function (ProductDetail: ReturnType<typeof import('widgets/product/ProductDetail').default>) {
    /**
     * @class RecentlyViewedMixin
     * @augments ProductDetail
     * @subcategory product
     * @classdesc Represents RecentlyViewed functionality.
     * Uses only if Einstein recently viewed functionality for some reason cannot be used. Implement next features:
     * 1. Checks if products tracking allowed
     * 2. Stores product IDs (based on visited pages) in the cookie `recentlyViewed` if tracking allowed
     * @property {boolean} data-recently-viewed-tracking-allowed - determines if recently view tracking allowed based on preference
     * @property {string} data-master-pid - master product ID
     */
    class RecentlyViewedMixin extends ProductDetail {
        prefs() {
            return {
                recentlyViewedTrackingAllowed: false,
                masterPid: '',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.trackViewedItem(this.prefs().masterPid);
        }

        /**
         * @description Determines if recently viewed tracking allowed
         * @returns {boolean} result
         */
        isTrackingAllowed() {
            return (Number(getCookie(DO_NOT_TRACKED_COOKIE)) !== 1) && this.prefs().recentlyViewedTrackingAllowed;
        }

        /**
         * @description Saves visited product IDs to cookies
         * @param {Array} arr - array with product IDs of visited products
         * @returns {void}
         */
        saveViewedItems(arr = []) {
            setCookie(RECENTLY_VIEWED_ENTRY, arr.join('|'), 0, true);
        }

        /**
         * @description Parses visited product IDs from the string
         * @param {string} str - string with product IDs
         * @returns {Array} result - array with product IDs
         */
        getViewedItems(str = '') {
            if (!str) {
                return [];
            }

            return str.split('|');
        }

        /**
         * @description Saves visited product to cookies
         * @param {string} pid - visited master product ID
         * @returns {void}
         */
        trackViewedItem(pid = '') {
            if (!pid || !this.isTrackingAllowed()) {
                return;
            }

            let trackedProducts = this.getViewedItems(getCookie(RECENTLY_VIEWED_ENTRY));

            trackedProducts = trackedProducts.filter(viewedItem => viewedItem !== pid);

            trackedProducts.unshift(pid);

            if (trackedProducts.length > RECENTLY_VIEWED_ITEMS_LIMIT) {
                trackedProducts = trackedProducts.slice(1, RECENTLY_VIEWED_ITEMS_LIMIT);
            }

            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
            this.saveViewedItems(trackedProducts);
        }
    }

    return RecentlyViewedMixin;
}
