/**
 * @typedef {ReturnType<typeof import('widgets/product/AddAllToCartMixin').default>} AddAllToCartMixinBase
 * @typedef {InstanceType<typeof import('widgets/toolbox/RefElement').RefElement>} refElement
 * @typedef {InstanceType<typeof import('core/product/SetProductDetail').default>} SetProductDetail
 */

/**
 * @description Extended AddAllToCartMixin implementation
 *
 * This class is not intended to have a separate DOM representation.
 *
 * @param {AddAllToCartMixinBase} AddAllToCartMixinBase widget for extending
 * @returns {typeof AddAllToCartMixin} Add to Cart mixin widget
 */
export default function (AddAllToCartMixinBase) {
    /**
     * @class AddAllToCartMixin
     * @augments AddAllToCartMixinBase
     * @subcategory product
     * @classdesc Represents product set component with the next features:
     * 1. Adds all product set products to the basket
     * 2. Shows alert message after adding product set products to the basket
     *
     * @property {string} data-text-network-error - network error text
     */
    class AddAllToCartMixin extends AddAllToCartMixinBase {
        prefs() {
            return {
                classesBusy: 'm-busy',
                ...super.prefs()
            };
        }

        /**
         * @description Returns product set data for the set items
         * @returns {array} result
         */
        getProductSetItems() {
            const productSetItems = [];

            this.eachChild((child) => {
                if (child.prefs().productSetItem && this.productSetSelected(child)) {
                    let isOutOfStock = false;

                    child.getById('addToCart', (addToCart) => {
                        isOutOfStock = !!addToCart.prefs().isOutOfStock;
                    });

                    productSetItems.push({
                        pid: child.prefs().currentProductId || child.prefs().pid,
                        quantity: child.prefs().selectedQuantity,
                        available: !isOutOfStock,
                        readyToOrder: child.prefs().readyToOrder
                    });
                }
            });

            return productSetItems;
        }

        /**
         * @description returns product set item selection status
         *
         * @param {SetProductDetail} productSetItem - product set item ref element
         * @returns {boolean} true flag if product set item selected
         */
        productSetSelected(productSetItem) {
            const SET_ITEM_SELECTED_VALUE = 'on';

            return productSetItem.productSelected() === SET_ITEM_SELECTED_VALUE;
        }

        /**
         * @description checks that all selected product is added to cart
         * @param {object} addToCartResult - add to cart result
         * @param {number} productsCount - product count
         * @returns {boolean} result
         */
        isAllProductAdded(addToCartResult, productsCount) {
            return addToCartResult.messages && (addToCartResult.messages.length === productsCount);
        }

        /**
         * @description shows post add to cart messages
         * @param {boolean} showAccessibilityAlerts - use accessibility flag
         * @param {Array.<string>} messages - array with messages
         * @returns {string} result
         */
        getPostAddToCartMessage(showAccessibilityAlerts, messages) {
            const message = showAccessibilityAlerts
                ? this.prefs().accessibilityAlerts.addedsettocart
                : messages.join('<br>');

            return message;
        }

        /**
         * @description checks that is current product set added to cart
         * @param {SetProductDetail} currentProductSet - current product set
         * @param {string} productID - added to cart product set item id
         * @returns {boolean} result
         */
        isAddedToCartProductSet(currentProductSet, productID) {
            return currentProductSet.prefs().productSetItem
                && this.productSetSelected(currentProductSet)
                && currentProductSet.prefs().pid
                && currentProductSet.prefs().pid.toString() === productID;
        }

        /**
         * @description gets added to cart product set items
         * @param {object} cartModel - updated cart model
         * @param {object} pidsObj - product set object
         * @returns {Array.<SetProductDetail>} result
         */
        getAddedToCartProductSets(cartModel, pidsObj) {
            const pidIDs = pidsObj.map((pidObj) => pidObj.pid.toString());
            const addedSetItemsIDs = pidIDs.filter((pidID) => cartModel.itemsIds.includes(pidID));
            const productWidgets = (addedSetItemsIDs || []).reduce((setWidgets, productID) => {
                this.eachChild((child) => {
                    if (this.isAddedToCartProductSet(child, productID)) {
                        setWidgets.push(child);
                    }
                });

                return setWidgets;
            }, []);

            return productWidgets;
        }

        /**
         * @description Handles a result of product set adding to cart
         * @param {object} response - response
         * @param {boolean} isFullProductSet - Identifies if all product set items are added
         * @param {number} productsCount - products count to be added to the cart
         * @returns {void}
         */
        postAddAllToCart(response, isFullProductSet, productsCount) {
            if (!response.error && response.cart && response.messages) {
                const cartModel = response.cart;

                cartModel.showMinicart = this.prefs().showMinicartOnProductAdd;

                const productSetItems = this.getProductSetItems();
                const pidsObj = this.getPidsObj(productSetItems);
                const isAllProductsAdded = this.isAllProductAdded(response, productsCount);
                const showAccessibilityAlerts = isFullProductSet && isAllProductsAdded;
                const message = this.getPostAddToCartMessage(showAccessibilityAlerts, response.messages);
                const productWidgets = this.getAddedToCartProductSets(cartModel, pidsObj);

                this.showAddAllToCartAlert(false, message);
                this.showJustAddedProductCart(response);
                this.eventBus().emit('product.added.to.cart', cartModel, this, productWidgets);
            } else {
                this.showAddAllToCartAlert(true, response.message);
            }
        }

        /**
         * @description shows cart quick view
         *
         * @param {object} response - result of the adding to cart call
         * @param {object} response.cart - cart model
         * @param {string} response.pid - added product identifier
         */
        showJustAddedProductCart(response) {
            const cartModel = response.cart;
            const productID = response.pid;
            const subscriptionID = response.subscriptionID;
            const pidsObj = response.pidsObj;

            cartModel.action = response.action;

            if (response.error && this.prefs().showMessageOnProductAdd) {
                this.eventBus().emit('product.addedTocart.with.error', response);
                this.showCartMessage(response.message, response.error);

                return;
            }

            if (!cartModel || !cartModel.numItems) {
                return;
            }

            this.showProgressBar();

            this.eventBus().emit('show.just.added.cart', {
                productID,
                cartModel,
                subscriptionID,
                pidsObj
            });

            cartModel.showMinicart = false;
            this.eventBus().emit('cart.updated', { cartModel }, this);

            if (window.contexts.includes('cart')) {
                this.eventBus().emit('product.added.to.cart', { cartModel }, this);
            } else {
                this.eventBus().emit('just.added.product.to.cart', { cartModel }, this);
            }

            this.hideProgressBar();
        }

        showCartMessage(msg, error = false) {
            this.getById(this.prefs().addToCartMsg, (addToCartMsg) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                if (typeof this.spacesLength === 'undefined') {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                    this.spacesLength = 0;
                }
                // It's needed to force render previously added success message. If we try to add the same product
                // several times, success message alert will not be announced by a screen reader.

                // @ts-expect-error ts-migrate(2339) FIXME: Property 'spacesLength' does not exist on type 'Ad... Remove this comment to see the full error message
                msg = msg.concat(Array(++this.spacesLength).join(this.prefs().zeroWidthSpaceSymbol));

                addToCartMsg.render('template', { msg, error }, addToCartMsg.ref('container')).then(() => addToCartMsg.show());
            });
        }

        showProgressBar() {
            this.ref('self').addClass(this.prefs().classesBusy);
        }

        hideProgressBar() {
            this.ref('self').removeClass(this.prefs().classesBusy);
        }
    }

    return AddAllToCartMixin;
}
