/**
 * @typedef {ReturnType <typeof import('widgets/forms/InputCheckbox').default>} InputCheckbox
 */

/**
 * @description Base InputCheckbox implementation
 * @param {InputCheckbox} InputCheckbox -  widget for extending
 * @returns {typeof BuyTogetherCheckbox} Input Checkbox class
 */
export default function (InputCheckbox) {
    class BuyTogetherCheckbox extends InputCheckbox {
        /**
         * @description disables checkbox
         */
        disableCheckbox() {
            this.ref('field').prop('disabled', true);
        }

        /**
         * @description preselects checkbox
         */
        preselectCheckbox() {
            this.ref('field').prop('checked', true);
        }

        /**
         * unselects checkbox
         */
        unselectCheckbox() {
            this.ref('field').prop('checked', false);
        }
    }

    return BuyTogetherCheckbox;
}
