/**
 * @typedef {ReturnType<typeof import('widgets/product/AlternativeImage').default>} BaseAlternativeImage
 */

/**
 * @description AlternativeImage extension
 * @param {BaseAlternativeImage} BaseAlternativeImage Base widget for extending
 * @returns {typeof AlternativeImage} Alternative Image widget
 */
export default function (BaseAlternativeImage) {
    /**
     * @class AlternativeImage
     * @augments BaseAlternativeImage
     */
    class AlternativeImage extends BaseAlternativeImage {
        /**
         * @description Prepare alternative image viewport dependent src.
         * The trick is get already defined by browser image from set and prepare same image for alt.
         * If project has completely different images on breakpoints this should be extended with
         * update image src on viewport change.
         * @returns {string} alternative image url
         */
        getAlternativeImageSrc() {
            const altImageUrl = this.ref('alternativeViewContainer').attr('data-alt-image-url');
            const baseImage = this.ref('tileImage').get();

            if (!(baseImage instanceof HTMLImageElement) || !altImageUrl) {
                return '';
            }

            const baseImageUrl = baseImage.currentSrc || baseImage.src;
            const altImageUrlParsed = new URL(altImageUrl);
            const baseImageUrlParsed = new URL(baseImageUrl);
            const disQueryString = baseImageUrlParsed.search;

            if (altImageUrlParsed.search === disQueryString) {
                return altImageUrl;
            }

            return altImageUrlParsed.origin + altImageUrlParsed.pathname + disQueryString;
        }
    }

    return AlternativeImage;
}
