/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductSet').default>} ProductSetBase
 * @typedef {InstanceType<ReturnType<typeof import('widgets/product/SetProductDetail').default>>} setProductDetail
 */

import { getJSONByUrl } from 'widgets/toolbox/ajax';
import { scrollWindowTo } from 'widgets/toolbox/scroll';

/**
 * @description ProductSet widget
 * @param {ProductSetBase} ProductSetBase Base widget for extending
 * @returns {typeof ProductSet} SetProductDetail class
 */

export default function (ProductSetBase) {
    /**
     * @class ProductSet
     * @augments ProductSetBase
     */
    class ProductSet extends ProductSetBase {
        prefs() {
            return {
                productSetInfoUrl: '',
                textShowMore: '',
                textShowLess: '',
                textViewedProductsSm: '',
                textViewedProductsLg: '',
                textViewedProductsAll: '',
                classesAllProducts: 'm-all_products',
                ...super.prefs()
            };
        }

        init() {
            super.init();

            this.togglePriceTotals(false, true);
            this.initEvents();
            this.updateSetPriceTotal();
        }

        /**
         * @description inits global events
         */
        initEvents() {
            this.eventBus().on('after.change.product.set.attribute', 'onChangeAttribute');
            this.eventBus().on('carousel.loaded', 'handleMoreIdeasLink');
        }

        /**
         * @description Scroll to Reviews section
         * @returns {void}
         */
        scrollToReviews() {
            this.has('reviews', reviews => scrollWindowTo(reviews.get(), true));
        }

        /**
         * @description Update certain page elements on page loading
         * @returns {void}
         */
        handleMoreIdeasLink() {
            this.has('showMoreIdeas', showMoreIdeas => showMoreIdeas.show());
            this.has('showMoreIdeasSecondLink', showMoreIdeasSecondLink => showMoreIdeasSecondLink.show());
        }

        /**
         * @description Open Browser print flyout
         * @returns {void}
         */
        print() {
            window.print();
        }

        togglePriceTotals(setPriceState, zeroPriceState) {
            this.getById('priceBlock', priceBlock => priceBlock.ref('self').toggle(setPriceState));
            this.ref('totalPriceZero').toggle(zeroPriceState);
        }

        /**
         * @description updates price totals
         */
        updateSetPriceTotal() {
            const SetProductDetail = this.getConstructor('setProductDetail');

            const productsSelected = [];

            this.eachChild(child => {
                if (child instanceof SetProductDetail && child.productSelected()) {
                    productsSelected.push(child.getProductId());
                }
            });

            const productSetPriceBlock = this.getById('priceBlock', priceBlock => priceBlock.ref('self'));

            if (!productsSelected.length) {
                this.togglePriceTotals(false, true);
                this.manageAddSetToCart(false);

                return;
            }

            getJSONByUrl(this.prefs().productSetInfoUrl, {
                productSetId: this.prefs().pid,
                productSetSelectedProducts: productsSelected.join(',')
            }).then(response => {
                if (response && response.total) {
                    this.render(
                        'setPriceTpl',
                        { price: response.total },
                        productSetPriceBlock
                    ).then(this.togglePriceTotals.bind(this, true, false));

                    this.manageAddSetToCart(true);
                } else {
                    this.togglePriceTotals(false, true);
                    this.manageAddSetToCart(false);
                }
            });
        }

        /**
         * @description updates price totals after change product variation attributes
         */
        onChangeAttribute() {
            this.updateSetPriceTotal();
        }

        /**
         * @description Server request to get product set info/prices etc depending on pre-selected products
         * @returns {void}
         */
        onProductSelected() {
            this.updateSetPriceTotal();
        }

        /**
         * @description Enables or disable `add set to cart` button
         * @param {boolean} enable enable/disable button
         * @returns {void}
         */
        manageAddSetToCart(enable = true) {
            this.getById('addSetToCart', addSetToCart => {
                addSetToCart[enable ? 'enable' : 'disable']();
            });
        }

        toggleProductsView() {
            const productsContainer = this.ref('productsContainer');

            if (productsContainer.hasClass(this.prefs().classesAllProducts)) {
                productsContainer.removeClass(this.prefs().classesAllProducts);

                this.ref('smProductsViewButton').setText(this.prefs().textShowMore);
                this.ref('lgProductsViewButton').setText(this.prefs().textShowMore);

                this.ref('smProductsViewMessage').setText(this.prefs().textViewedProductsSm);
                this.ref('lgProductsViewMessage').setText(this.prefs().textViewedProductsLg);
            } else {
                productsContainer.addClass(this.prefs().classesAllProducts);

                this.ref('smProductsViewButton').setText(this.prefs().textShowLess);
                this.ref('lgProductsViewButton').setText(this.prefs().textShowLess);

                this.ref('smProductsViewMessage').setText(this.prefs().textViewedProductsAll);
                this.ref('lgProductsViewMessage').setText(this.prefs().textViewedProductsAll);
            }
        }
    }

    return ProductSet;
}
