import { TWidget } from 'widgets/Widget';
// TODO: JSDoc for all methods
// TODO: should be implemented as https://www.w3.org/TR/wai-aria-practices/#Listbox
// single select controls group (single tab stop element) with activation on click/space/enter
import { RefElement } from 'widgets/toolbox/RefElement';

/**
 * @description Base ColorSwatch implementation
 * @param Widget Base widget for extending
 * @returns Color Swatch widget
 */
export default function (Widget: TWidget) {
    /**
     * @class ColorSwatch
     * @augments Widget
     */
    class ColorSwatch extends Widget {
        selectColor(option) {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedOption' does not exist on type '... Remove this comment to see the full error message
            this.selectedOption = option;
            this.emit('change', this);
        }

        getSelectedOptions() {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedOption' does not exist on type '... Remove this comment to see the full error message
            if (this.selectedOption) {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedOption' does not exist on type '... Remove this comment to see the full error message
                return this.selectedOption;
            }

            return new RefElement([]);
        }
    }

    return ColorSwatch;
}
