import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/AjaxForm').default>} AjaxForm
 */

/**
 * @description EVoucherDataForm implementation
 * @param {AjaxForm} AjaxForm Base widget for extending
 * @returns {typeof EVoucherDataForm} EVoucherDataForm class
 */
export default function (AjaxForm) {
    /**
     * @class EVoucherDataForm
     * @augments AjaxForm
     */
    class EVoucherDataForm extends AjaxForm {
        /**
         * @description Prevent to submit form by Enter
         * @returns {void}
         */
        handleNoop() {

        }

        /**
         * @description Programmatically submits e-voucher form data to server
         * @param {string} pliUUID newly added to cart product line itrem ID
         * @returns {Promise} a promise after form submission
         */
        submitData(pliUUID) {
            this.submitting = true;
            return submitFormJson(
                this.getFormUrl(),
                {
                    pliUUID,
                    ...this.getFormFields()
                },
                this.ref('self').attr('method') === 'GET' ? 'GET' : 'POST'
            )
                .then(this.onSubmitted.bind(this))
                .catch(this.onError.bind(this))
                .finally(this.afterSubmission.bind(this));
        }

        /**
         * @description Just to use in further `then` promise chain
         * @param {object} data server response
         * @returns {object} server response
         */
        onSubmitted(data) {
            super.onSubmitted(data);
            return data;
        }
    }

    return EVoucherDataForm;
}
