import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('plugin_wishlists/wishlist/AddToWishlistMixin').default>} BaseAddToWishlistMixin
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Button').default>>} button
 */

/**
 * @description Extended AddToWishlistMixin widget
 * @param {BaseAddToWishlistMixin} BaseAddToWishlistMixin Base widget for extending
 * @returns {typeof EProductAddToWishlistMixin} ExtendedWishlistItem widget
 */
export default function (BaseAddToWishlistMixin) {
    class EProductAddToWishlistMixin extends BaseAddToWishlistMixin {
        /**
         * @description Handle click on the button
         * @listens dom#click
         * @param {button} button Target button
         */
        wishlistButtonClickHandler(button) {
            if (button.isActive()) {
                this.removeProductFromWishlist(button);
            } else {
                this.validateAndSubmit(button, this.addToWishlist);
            }
        }

        /**
         * @description submit item to wishlist
         * @param {button} addButton - button
         * @param {object} eVoucherData - form fields
         * @returns {Promise} Promise object represents server response for product updating
         */
        addToWishlist(addButton, eVoucherData = {}) {
            const addToWishlistUrl = window.sfccData.wishlistActionUrls?.addToWishlist;
            const options = this.prefs().productOptions;
            let customOptionValue = '';
            let optionId = '';
            let optionVal = '';

            if (options && options.length) {
                optionId = options[0].id;
                optionVal = options[0].selectedValueId;
                customOptionValue = this.getCustomOptionValue(options[0].id);
            }

            const amount = this.getEProductAmount();

            addButton.busy();
            this.getById(this.prefs().addToWishlistMsg, (labelAddToWishlistMsg) => labelAddToWishlistMsg.hide());

            return submitFormJson(addToWishlistUrl, {
                pid: this.prefs().pid || '',
                qty: this.prefs().selectedQuantity || '',
                optionId: optionId,
                optionVal: optionVal,
                customOptionValue: customOptionValue,
                amount: amount > 0 ? amount.toString() : '',
                ...eVoucherData
            })
                .then(response => {
                    this.handleSuccess(response);

                    return response;
                })
                .catch(() => {
                    this.showWishlistMessage(this.prefs().textNetworkError, true);
                })
                .finally(() => {
                    addButton.unbusy();
                });
        }
    }

    return EProductAddToWishlistMixin;
}
