/**
 * @typedef {ReturnType<typeof import('widgets/product/ProductDetail').default>} ProductDetailBase
 * @typedef {InstanceType<ReturnType<typeof import('widgets/forms/InputStepper').default>>} InputStepper
 */

import { submitFormJsonWithAbort } from 'widgets/toolbox/ajax';
import { showErrorLayout, appendParamsToUrl } from 'widgets/toolbox/util';

/**
 * @description SetProductDetail widget
 * @param {ProductDetailBase} ProductDetailBase Base widget for extending
 * @returns {typeof SetProductDetail} SetProductDetail class
 */

export default function (ProductDetailBase) {
    /**
     * @class SetProductDetail
     * @augments ProductDetailBase
     */
    class SetProductDetail extends ProductDetailBase {
        prefs() {
            return {
                selectedClass: 'm-selected',
                lowStockMessage: '',
                ...super.prefs()
            };
        }

        /**
         * @description Shows/hides possibility to add product into set depending on product type
         * @param {string} productType selected product type
         * @returns {void}
         */
        setSelectProduct(productType) {
            const selectProductMethod = productType !== 'variant' && productType !== 'standard' && productType !== 'bundle'
                ? 'hide' : 'show';

            this.getById('selectProduct', selectProduct => selectProduct[selectProductMethod]());
        }

        /**
         * @description Render product price
         * Additionally renders subtotal for product in a set
         * @param {object} product Product object
         * @returns {void}
         */
        renderPrice(product) {
            this.eachChild(child => {
                if (child.data('id') === 'priceBlock') {
                    if (child.data('priceMeaning') === 'subtotal'
                        && product.subtotal && product.subtotal.html) {
                        child.render('template', {}, undefined, product.subtotal.html);
                    } else if (child.data('priceMeaning') === 'basic'
                        && product.price && product.price.html) {
                        child.render('template', {}, undefined, product.price.html);
                    }
                }
            });
        }

        /**
         * @description Updates selection flag
         * @param {boolean} selectionFlag - selection flag
         * @param {string} selectionValue - selection value
         */
        updateProductSelection(selectionFlag) {
            if (!selectionFlag) {
                this.getById('selectProduct', (selectProduct) => {
                    selectProduct.unselectCheckbox();
                });
            } else if (selectionFlag) {
                this.getById('selectProduct', (selectProduct) => {
                    selectProduct.preselectCheckbox();
                });
            }
        }

        /**
         * @description updates availability section
         * @param {object} product - product object
         * @returns {Promise} rendering promise
         */
        updateAvailabilitySection(product) {
            if (product) {
                const isProductSelected = this.productSelected() === 'on';

                product.selected = isProductSelected;

                this.updateProductSelection(isProductSelected);
            }

            this.recommendationAvailable = product.available;

            return this.render('availabilitySectionTemplate', product, this.ref('availabilitySectionContainer'))
                .then(() => { this.eventBus().emit('after.change.product.set.attribute'); });
        }

        /**
         * @description updates callout section
         * @param {object} product - product object
         * @returns {Promise} rendering promise
         */
        updateCallOut(product) {
            const isReachedLimit = (product.readyToOrder || product.isInaccessibleVariant) && product.availability.isReachedLimit;
            let lowStockMessage = '';

            if (product.availability.isLowStock) {
                lowStockMessage = product.availability.messages[0];
            } else if (product.pdpAvailability.available && product.pdpAvailability.stock) {
                lowStockMessage = this.prefs().lowStockMessage.replace('{0}', product.pdpAvailability.stock);
            }

            const renderingData = {
                reachedLimitMessage: isReachedLimit ? product.availability.isReachedLimitMessage : '',
                hasPromotions: !!product.promotions,
                promotions: product.promotions,
                lowStockMessage
            };

            return this.render('callOutSectionTemplate', renderingData, this.ref('callOutSectionContainer'))
                .then(() => { this.eventBus().emit('after.change.product.set.attribute'); });
        }

        /**
         * @description After change attribute handler
         * @param {object} response response object
         * @returns {void}
         */
        afterChangeAttribute(response) {
            super.afterChangeAttribute(response);

            if (response && response.product) {
                this.setSelectProduct(response.product.productType);
                this.updateAvailabilitySection(response.product);
                this.updateCallOut(response.product);
            }
        }

        /**
         * @description trigger change from different widgets (swatch, select)
         * Saves `selectWidget.id` into a property for further global notifications etc.
         * Later, when triggering event, `selectWidget.id` will be analyzed in order to send with event correct data.
         * @param {InputStepper} selectWidget widget
         * @returns {Promise<object|null>} Promise object represents server response with attribute changing result
         */
        changeAttribute(selectWidget) {
            this.changeAttributeID = selectWidget.id;
            const selected = selectWidget.getSelectedOptions();

            if (!selected || selected.data('attrIsSelected')) {
                return Promise.resolve(null);
            }

            const requestURL = appendParamsToUrl(selected.data('attrUrl'), {
                partOfProductSet: true,
                productSetId: this.prefs().productSetId
            });

            const requestObject = submitFormJsonWithAbort(requestURL, undefined, 'GET', true);

            this.changeAttributeRequests.push(requestObject);

            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            return requestObject.promise
                .then((response) => {
                    this.afterChangeAttribute(response);

                    return response;
                })
                .catch(e => {
                    if (e.name === 'AbortError') {
                        return;
                    }

                    showErrorLayout(e);
                })
                .finally(() => {
                    // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
                    const index = this.changeAttributeRequests.indexOf(requestObject);

                    if (index > -1) {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'changeAttributeRequests' does not exist ... Remove this comment to see the full error message
                        this.changeAttributeRequests.splice(index, 1);
                    }
                });
        }

        /**
         * @description Event handler for product select via chakbox in a product set
         * @returns {void}
         */
        onProductSelect() {
            this.ref('self').toggleClass(
                this.prefs().selectedClass,
                this.productSelected()
            );

            this.emit('productselected');
        }

        /**
         * @description Checks if product is selected
         * @returns {boolean} if product is selected
         */
        productSelected() {
            return this.getById('selectProduct', selectProduct => selectProduct.getValue());
        }

        /**
         * @description Get current product ID
         * @returns {string} current product ID
         */
        getProductId() {
            return this.config.pid;
        }
    }

    return SetProductDetail;
}
