/**
 * @typedef {ReturnType<typeof import('widgets/product/RecentlyViewedMixin').default>} BaseRecentlyViewedMixin
 */

/**
 * @description Base RecentlyViewedMixin implementation
 * @param {BaseRecentlyViewedMixin} BaseRecentlyViewedMixin Base widget for extending
 * @returns {typeof RecentlyViewedMixin} RecentlyViewedMixin
 */
export default function (BaseRecentlyViewedMixin) {
    /**
     * @class RecentlyViewedMixin
     * @augments BaseRecentlyViewedMixin
     */
    class RecentlyViewedMixin extends BaseRecentlyViewedMixin {
        /**
         * @description Saves visited product to cookies
         * @param {string} pid - visited master product ID
         * @returns {void}
         */
        trackViewedItem(pid) {
            const refinedPid = (this.prefs().pid || pid).toString();

            super.trackViewedItem(refinedPid);
        }
    }

    return RecentlyViewedMixin;
}
