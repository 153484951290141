import { TWidget } from 'widgets/Widget';
import { scrollWindowTo } from 'widgets/toolbox/scroll';

/**
 * @param Widget Base widget for extending
 * @returns Product Set widget
 */
export default function (Widget: TWidget) {
    /**
     * @category widgets
     * @subcategory product
     * @class ProductSet
     * @augments Widget
     * @classdesc Represents product set component with the next features:
     * 1. Scrolls page to the product list, once `Show the collection` button pressed.
     *
     * @example <caption>Example of product set component usage</caption>
     *
     * <div
            data-widget="productSet"
            ...
        >
            ...
            <div class="l-pdp" data-ref="productsContainer">
                ....
            </div>
            <div class="l-pdp-main m-product_set_buy_all">
                ....
            </div>
        </div>
     */
    class ProductSet extends Widget {
        /**
         * @description Returns Widget configuration object
         * @returns {object} Widget configuration object
         */
        prefs() {
            return {
                productDetailId: 'productDetail-0',
                ...super.prefs()
            };
        }

        /**
         * @description Scrolls page to the products list and set focus on first product item
         * @returns {void}
         */
        onShopCollection() {
            const productsContainer = this.ref('productsContainer').get();

            if (productsContainer) {
                this.getById(this.prefs().productDetailId, (productDetail) => {
                    productDetail.getById('productName', (productName) => {
                        productName.ref('titleLink').focus();
                    });
                });
                scrollWindowTo(productsContainer, true);
            }
        }
    }

    return ProductSet;
}
