/**
 * @typedef {ReturnType<typeof import('widgets/Widget').default>} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Availability} Availability class
 */

export default function (Widget) {
    /**
     * @category widgets
     * @subcategory product
     * @class Availability
     * @augments Widget
     * @classdesc Represents Availability component
    */

    class Availability extends Widget {
        prefs() {
            return {
                pdpAvailability: {},
                ...super.prefs()
            };
        }

        openBackInStockDialog() {
            this.has('backInStockDialogTemplate', backInStockDialogTemplate => {
                this.eventBus().emit('dialogshow', {
                    content: backInStockDialogTemplate.prop('innerHTML'),
                    wrapperClasses: 'm-back_in-stock',
                    windowClasses: 'b-dialog-bg'
                });
            });
        }

        /**
         * @description Returns PDP availability object
         * @returns {object} PDP availability object
         */
        getAvailability() {
            return this.prefs().pdpAvailability;
        }

        /**
         * @description Stores updated PDP availability
         * @param {object} pdpAvailability updated PDP availability
         * @returns {void}
         */
        setAvailability(pdpAvailability) {
            this.setPref('pdpAvailability', JSON.stringify(pdpAvailability));
        }
    }

    return Availability;
}
