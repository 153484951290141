/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof Specifications} Specifications
 */

export default function (Widget) {
    class Specifications extends Widget {
        init() {
            const currentProduct = document.getElementById('current-product');

            if (!currentProduct) {
                return;
            }

            this.currentProductSpecifications = JSON.parse(currentProduct?.getAttribute('data-items'));
            this.itemsSpecs = [...this.ref('self').get().querySelectorAll('.b-product_specifications')];
            this.addHeadings();
            this.parseData();
        }

        /**
         * @description Add comparison table headings
        */
        addHeadings() {
            let rows = '';

            this.currentProductSpecifications.forEach((spec) => {
                if (spec.id === 'washcare') {
                    return;
                }

                rows += `<li class="b-product_specifications-row">
                            <div class="b-product_specifications-label">
                                ${spec.name}
                            </div>
                        </li>`;
            });

            const html = `<ul class="b-product_specifications m-comparison">${rows}</ul>`;

            this.ref('headings').get().innerHTML = html;
        }

        /**
         * @description Parse specifications from each product
        */
        parseData() {
            this.itemsSpecs?.forEach(spec => {
                const data = JSON.parse(spec.getAttribute('data-items'));
                const obj = {};

                data.forEach(item => {
                    obj[item.id] = {
                        name: item.name,
                        value: item.value
                    };
                });

                this.renderItemSpecs(spec, obj);
            });
        }

        /**
         * @description Add comparison table headings
         * @param {HTMLElement} _el HTMLElement
         * @param {Object} obj object with item's specifications data
        */
        renderItemSpecs(_el, obj) {
            let rows = '';

            this.currentProductSpecifications.forEach((spec) => {
                if (spec.id === 'washcare') {
                    return;
                }

                let name = spec.name;
                let value = '-';

                if (obj[spec.id]) {
                    ({ name = '', value = '-' } = obj[spec.id]);
                }

                rows += `<li class="b-product_specifications-row">
                            <div class="b-product_specifications-label">
                                ${name}
                            </div>
                            <div class="b-product_specifications-value">
                                ${value}
                            </div>
                        </li>`;
            });

            _el.innerHTML = rows;
        }
    }

    return Specifications;
}
