/**
 * @typedef {typeof import('widgets/Widget').default} Widget
 * @typedef {InstanceType<ReturnType<typeof import('core/product/ProductDetail').default>>} productDetail
 */

/**
 * @param {Widget} Widget Base widget for extending
 * @returns {typeof ProductDetailContainer} ProductDetailContainer class
 */

export default function (Widget) {
    /**
     * @class ProductDetailContainer
     * @augments Widget
    */

    class ProductDetailContainer extends Widget {
        prefs() {
            return {
                classesStandardHeader: 'm-standard_header',
                similarProductsCarouselPlace: 'none',
                ...super.prefs()
            };
        }

        /**
         * @description Widget intitalization logic
         * @returns {void}
         */
        init() {
            super.init();
            this.handleProductAvailability();
            this.eventBus().on('update.availability', 'handleProductAvailability');
        }

        /**
         * @description Handles OOS product logic
         * @returns {void}
         */
        handleProductAvailability() {
            const productDetail = /** @type {productDetail} */ (this.getById('productDetail', widget => widget));
            const availabilityObject = productDetail.getProductAvailabilityObject();
            const headerText = productDetail.getSimilarProductsCarouselHeader();

            if (!headerText) {
                this.hideTopSlot();
                this.hideBottomSlot();
            } else if (this.prefs().similarProductsCarouselPlace === 'top') {
                this.showTopSlot(headerText);
                this.hideBottomSlot();
            } else if (this.prefs().similarProductsCarouselPlace === 'bottom') {
                this.hideTopSlot();
                this.showBottomSlot(headerText);
            } else if (!availabilityObject.available) {
                if (availabilityObject.availableCC) {
                    this.showTopSlot(headerText, true);
                    this.showBottomSlot(headerText, true);
                } else {
                    this.showTopSlot(headerText);
                    this.hideBottomSlot();
                }
            }
        }

        hideTopSlot() {
            this.has('topSlotsUp', topSlot => topSlot.hide());
        }

        showTopSlot(headerText = '', headerOnly = false) {
            this.has('topSlotHeaderUp', topSlotHeader => {
                topSlotHeader.prop('innerHTML', headerText || '');
            });
            this.has('topSlotCarouselUp', topSlotCarousel => {
                topSlotCarousel[headerOnly ? 'hide' : 'show']();
            });
            this.has('topSlotsUp', topSlot => {
                topSlot[headerText ? 'show' : 'hide']();
            });
        }

        hideBottomSlot() {
            this.getById('productDetail', productDetail => {
                productDetail.has('topSlots', topSlot => topSlot.hide());
            });
        }

        showBottomSlot(headerText = '', standardHeader = false) {
            this.getById('productDetail', productDetail => {
                productDetail.has('topSlotHeader', topSlotHeader => {
                    topSlotHeader.prop('innerHTML', headerText || '');
                    topSlotHeader[standardHeader ? 'hide' : 'show']();
                });
                productDetail.has('topSlots', topSlot => {
                    topSlot.toggleClass(this.prefs().classesStandardHeader, !!standardHeader);
                    topSlot[headerText ? 'show' : 'hide']();
                });
            });
        }

        /**
         * @description Handle product view update
         * @returns {void}
         */
        onProductViewUpdated() {
            this.handleProductAvailability();
        }
    }

    return ProductDetailContainer;
}
