/**
 * @param AjaxForm Base widget for extending
 * @returns BackInStock widget
 */
export default function (AjaxForm: ReturnType<typeof import('widgets/forms/AjaxForm').default>) {
    /**
     * @category widgets
     * @subcategory forms
     * @class BackInStock
     * @augments AjaxForm
     * @classdesc Represents BackInStock component with next features:
     * 1. Updates product id of the product available for back in stock
     *
     * @property {string} data-widget - Widget name `backInStock`
     * @example <caption>Example of BackInStock widget usage</caption>
     *  <form>
     *      ...
     *      <input
     *          type="hidden"
     *          data-widget="inputHidden"
     *          data-ref="field"
     *          data-id="backInStockProductId"
     *          value="${product.id}"
     *          name="${pdict.backInStockForm.productID.htmlName}"
     *      />
     *      ...
     *  /form>
     */
    class BackInStock extends AjaxForm {
        updateProductId(productId) {
            this.getById('backInStockProductId', (backInStockProductId) => {
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'setValue' does not exist on type 'Widget... Remove this comment to see the full error message
                backInStockProductId.setValue(productId);
            });
        }
    }

    return BackInStock;
}
