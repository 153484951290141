import { timeout } from 'widgets/toolbox/util';

/**
 * @typedef {ReturnType<typeof import('widgets/forms/ajax/BackInStock').default>} BaseBackInStock
 */

/**
 * @description BackInStock implementation
 * @param {BaseBackInStock} BaseBackInStock Base widget for extending
 * @returns {typeof BackInStock} BackInStock class
 */
export default function (BaseBackInStock) {
    /**
     * @class BackInStock
     * @augments BaseBackInStock
     */
    class BackInStock extends BaseBackInStock {
        /**
         * @description Handles server response
         * @emits AjaxForm#submit
         * @param {object} data Server JSON response once form submitted
         * @param {string} [data.success] - If form submission was success
         * @param {string} [data.redirectUrl] - if not empty - redirect to specified location should be executed
         * @param {boolean|string|string[]} [data.error] - error messages/message from server
         * @param {object[]} [data.fields] - form fields with errors
         * @returns {void}
         */
        onSubmitted(data) {
            if (data.success && data.redirectUrl) {
                window.location.assign(data.redirectUrl);
            } else if (data.error) {
                if (data.redirectUrl) {
                    window.location.assign(data.redirectUrl);
                } else {
                    this.setError(Array.isArray(data.error) ? data.error.join('<br/>') : data.error);
                }
            } else if (data.fields) {
                Object.entries(data.fields).forEach(([name, errorMsg]) => {
                    this.getById(name, (input) => {
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'setError' does not exist on type 'Widget... Remove this comment to see the full error message
                        input.setError(errorMsg);
                    });
                });
            } else {
                timeout(() => {
                    /**
                     * @description Event to submit AjaxForm
                     * @event AjaxForm#submit
                     */
                    this.emit('submit', data);
                    this.ref('contentBlock').hide();
                    this.ref('successBlock').show();
                });
            }
        }
    }

    return BackInStock;
}
