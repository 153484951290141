import { submitFormJson } from 'widgets/toolbox/ajax';

/**
 * @typedef {ReturnType<typeof import('widgets/product/AddToCartMixin').default>} BaseAddToCartMixin
 * @typedef {InstanceType<ReturnType<typeof import('widgets/global/Button').default>>} button
 */

/**
 * @param {BaseAddToCartMixin} BaseAddToCartMixin Base widget for extending
 * @returns {typeof PlusProductAddToCartMixin} PlusProductAddToCartMixin class
 */

export default function (BaseAddToCartMixin) {
    /**
     * @category widgets
     * @subcategory product
     * @class EProductAddToCartMixin
     * @augments BaseAddToCartMixin
     * @classdesc Represents EProductAddToCartMixin component
    */

    class PlusProductAddToCartMixin extends BaseAddToCartMixin {
        prefs() {
            return {
                ...super.prefs()
            };
        }

        /**
         * @description Add product to cart
         * @param {button} button add to cart button
         * @returns {void}
         */
        addToCart(button) {
            if (this.prefs().processingRequest) {
                return;
            }

            this.setPref('processingRequest', true);
            button.startProcess();

            this.showProgressBar();

            const addToCartBtnPrefs = button.prefs();

            submitFormJson(addToCartBtnPrefs.addToCartUrl, {
                pid: this.prefs().pid,
                quantity: this.prefs().selectedQuantity
            }).then((response) => {
                this.setPref('processingRequest', false);
                this.postAddProduct(response);

                button.stopProcess(this.prefs().addedToCartLabel);
                this.hideProgressBar();

                this.getById('addToCartSticky', addToCartBtn => {
                    addToCartBtn.stopProcess(this.prefs().addedToCartLabel);
                });
            })
                .catch(() => {
                    this.setPref('processingRequest', false);
                    button.stopProcess();
                    this.hideProgressBar();

                    this.showCartMessage(this.prefs().textNetworkError, true);
                });
        }
    }

    return PlusProductAddToCartMixin;
}
